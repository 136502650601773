import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";
import ReactPlayer from 'react-player'


import iconDirection from '../../../img/projects/Elipse 39.svg';


import ImgOne from '../../../img/details/detailfiveteen/1.mp4';
import ImgTwo from '../../../img/details/detailfiveteen/2_PORTADA.gif';
import ImgThree from '../../../img/details/detailfiveteen/3.jpg';
import ImgFour from '../../../img/details/detailfiveteen/4.jpg';
import ImgFive from '../../../img/details/detailfiveteen/5.gif';
import ImgSix from '../../../img/details/detailfiveteen/6.jpg';
import Brandin from '../../../img/details/detailfiveteen/7.jpg';
 
import ImgEight from '../../../img/details/detailfiveteen/8.jpg';
import ImgNine from '../../../img/details/detailfiveteen/9.gif';
import ImgTen from '../../../img/details/detailten/Img10.jpeg';
import ImgEleven from '../../../img/details/detailten/Img11.jpeg';

 
import ImgTwelve from '../../../img/details/detailten/Img12.jpeg';
import ImgTherteen from '../../../img/details/detailten/Img13.jpeg';
import ImgFourteen from '../../../img/details/detailten/Img14.jpeg';
import ImgFiveteen from '../../../img/details/detailten/Img15.jpeg';
import ImgSixteen from '../../../img/details/detailten/Img16.jpeg';
import ImgEightteen from '../../../img/details/detailten/Img17.jpeg';





import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >


                     <div className="Video">                
                            <ReactPlayer
                            url="https://tbestudio.com/videos/tbepage/1.mp4"
                            className='react-player'
                            playing
                            width='100%'
                            min-height='100%'
                            controls= 'true'

                            />                
                    </div>   
                           </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">BIG CITY</text>
                <text>
                    <label>THE BRAND</label>
                    Big City is a fashion brand with a casual focus, offering an urban touch in its catalog. Its main sales points are Mercado Libre and Shein, with Shein experiencing the highest growth in recent months.
                </text>
                <text>
                    <label>THE CHALLENGE</label>
                    To create a brand that stands out, distinct in its communication style with a lot of personality. We wanted to give it a visual twist to generate a timeless brand, capable of adapting to fashion trends.
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%" />  
                </div>
            </div>  




        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;