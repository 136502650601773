import React from "react";
import "./App.scss";

import HeaderNav from "./components/nav/services/";
export default function App(props) {
  return (
    <div className="App">            
      <HeaderNav name={props.name}></HeaderNav>                   
    </div>
  );
}

