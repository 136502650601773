import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter, Route,Link,Routes} from 'react-router-dom'

import './fonts/Summer-Faith.otf';
import './fonts/Montserrat-Regular.otf';
import './fonts/Summer Faith.ttf';
import './fonts/Helvetica.ttf';
import './fonts/HelveticaNeue.otf';


import HeaderNavContact from './components/nav/contact'
import HeaderNavProjects from './components/nav/projects'

 
import DetailProjectOne from './components/nav/detailsproject/projectone/index'
import DetailProjectTwo from './components/nav/detailsproject/projecttwo/index'
import DetailProjectThree from './components/nav/detailsproject/projectthree/index'
import DetailProjectFour from './components/nav/detailsproject/projectfour/index'
import DetailProjectFive from './components/nav/detailsproject/projectfive/index'
import DetailProjectSix from './components/nav/detailsproject/projectsix/index'

import DetailProjectSeven from './components/nav/detailsproject/projectseven/index'
import DetailProjectEight from './components/nav/detailsproject/projecteight/index'
import DetailProjectNine from './components/nav/detailsproject/projectnine/index'

import DetailProjectTen from './components/nav/detailsproject/projectten/index'





import DetailProjectEleven from './components/nav/detailsproject/projectEleven/index'
import DetailProjectTwelve from './components/nav/detailsproject/projectTwelve/index'
import DetailProjectThirteen from './components/nav/detailsproject/projectThirteen/index'
import DetailProjectFourteen from './components/nav/detailsproject/projectFourteen/index'
import DetailProjectFiveteen from './components/nav/detailsproject/projectFiveteen/index'
import DetailProjectSixteen from './components/nav/detailsproject/projectSixteen/index'



import PageOne from './components/views/pageone'
import PageTwo from './components/views/pagetwo'
import PageThree from './components/views/pagethree'



import Loading from './components/views/loading'
import { Background } from 'react-parallax';

import ContainerTransicion from './components/views/transicion';
import NewCarrusel from './components/views/newcarrusel';

import Animacion from './components/views/animacionhome'

ReactDOM.render(
  <BrowserRouter >
  <Routes>
      <Route  path='/' element={<App name="home" />} />
      <Route  path='/servicios' element={<App name="servicios" />} />
      <Route   path='/contact' element={<HeaderNavContact />} />
      <Route  path='/projects' element={<HeaderNavProjects name='home' />} />
      <Route  path='/projects/one' element={<HeaderNavProjects name='one' />} />
      <Route  path='/projects/two' element={<HeaderNavProjects name='two' />} />
      <Route  path='/projects/three' element={<HeaderNavProjects name='three' />} />
      <Route  path='/detailone' element={<DetailProjectOne />} />
      <Route  path='/detailtwo' element={<DetailProjectTwo />} />
      <Route  path='/detailthree' element={<DetailProjectThree />} />
      <Route  path='/detailfour' element={<DetailProjectFour />} />
      <Route  path='/detailfive' element={<DetailProjectFive />} />
      <Route  path='/detailsix' element={<DetailProjectSix />} />
      <Route  path='/detailseven' element={<DetailProjectSeven />} />
      <Route  path='/detaileight' element={<DetailProjectEight />} />
      <Route  path='/detailnine' element={<DetailProjectNine />} />
      <Route  path='/detailten' element={<DetailProjectTen />} />
      

      <Route  path='/Noirniche' element={<DetailProjectEleven />} />
      <Route  path='/Amuze' element={<DetailProjectTwelve />} />
      <Route  path='/Cookiecad' element={<DetailProjectThirteen />} />
      <Route  path='/Elemate' element={<DetailProjectFourteen />} />
      <Route  path='/Bigcity' element={<DetailProjectFiveteen />} />      
      <Route  path='/Hivemind' element={<DetailProjectSixteen />} />
      

      








      <Route  path='/pageone' element={<PageOne />} />
      <Route  path='/pagetwo' element={<PageTwo />} />
      <Route  path='/pagethree' element={<PageThree />} />

      <Route  path='/carrusel' element={<Loading />} />

      <Route  path='/new' element={<Animacion />} />
      
  </Routes>
</BrowserRouter> ,
  document.getElementById('root')
);






// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
