import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailnine/M2C1-1.jpg';
import ImgTwo from '../../../img/details/detailnine/M2C1-2.jpg';
import ImgThree from '../../../img/details/detailnine/M2C1-3.jpg';
import ImgFour from '../../../img/details/detailnine/M2C1-4.jpg';
import ImgFive from '../../../img/details/detailnine/M2C1-5.jpg';
import ImgSix from '../../../img/details/detailnine/M2C1-6.jpg';
import Brandin from '../../../img/details/detailnine/M2C1-7.jpg';
 
import ImgEight from '../../../img/details/detailnine/M2C1-8.jpg';
import ImgNine from '../../../img/details/detailnine/M2C1-9.jpg';
import ImgTen from '../../../img/details/detailnine/M2C1-10.png';
import ImgEleven from '../../../img/details/detailnine/M2C1-11.jpg';


 
import ImgTwelve from '../../../img/details/detailnine/M2C1-12.jpg';
import ImgTherteen from '../../../img/details/detailnine/M2C1-13.jpeg';
import ImgFourteen from '../../../img/details/detailnine/M2C1-14.jpg';
import ImgFiveteen from '../../../img/details/detailnine/M2C1-15.jpg';
import ImgSixteen from '../../../img/details/detailnine/M2C1-16.jpg';
import ImgEightteen from '../../../img/details/detailnine/M2C1-17.jpg';
import ImgNineteen from '../../../img/details/detailnine/M2C1-18.jpg';
import ImgTwenty from '../../../img/details/detailnine/M2C1-19.jpg';
import ImgTwentyOne from '../../../img/details/detailnine/M2C1-20.jpg';

import ImgTwentyTwo from '../../../img/details/detailnine/M2C1-21.jpg';
import ImgTwentyThree from '../../../img/details/detailnine/M2C1-22.jpg';
import ImgTwentyFour from '../../../img/details/detailnine/M2C1-23.jpg';
import ImgTwentyFive from '../../../img/details/detailnine/M2C1-24.jpg';
import ImgTwentySix from '../../../img/details/detailnine/M2C1-25.jpg';





import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >
                     <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%" />  
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">M2C1 Solutions</text>
                <text>
                    <label>THE BRAND</label>
                    tBE was engaged to name and design the brand from A to Z, establishing the company as not just a service but a brand.
                    <br></br>
                    A brand that provides solutions both the manufacturer and contractors can rely on to partner with in the refacing process. This requires a dynamic, powerful and trustworthy brand culture built on service and efficiency that is Measurably Better.
                </text>
                <text>
                    <label>THE MISSION</label>
                    Kitchen remodeling is the way to make the biggest impact in a property's value, it is also the most expensive and time consuming aspect of a remodel.
                    <br></br>
                    We understood the need for the brand to project confidence and quality giving contractors the assurance that cabinet refacing is a viable solution with M2C1.
                    <br></br>
                    Our design needed to transmit that the customer will not only save time and money but will receive the service and quality necessary to create lasting value for their properties.
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgTen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgEleven} alt="React Logo"  width="100%" />  
                </div>
            </div>  








            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwelve} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTherteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgFourteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgFiveteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgSixteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgEightteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgNineteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwenty} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyOne} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyThree} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyFour} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyFive} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentySix} alt="React Logo"  width="100%" />  
                </div>
            </div>  




        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;