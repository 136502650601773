import React, {useState} from "react";
import './index.scss'
import './ipad.scss'
import './mobile.scss'
import { Cross as Hamburger } from 'hamburger-react'

import Logo from "../../img/header/logo";
import IconWhats from "../../img/header/whats";
import IconInsta from "../../img/header/insta";
import IconInstaMovil from "../../img/header/InstaMovil.svg";
import IconInstaMovilBlack from "../../img/header/InstaMovilBlack.svg";
import IconWhatsMovil from "../../img/header/WhatsWhiteMovil.svg";
import IconWhatsMovilBlack from "../../img/header/WhatsBlackMovil.svg";
import IconFace from "../../img/header/facebook";
import IconBe from "../../img/header/be";


import InstaOpenMovil from '../../img/header/InstaOpenMovil.svg'
import BeOpenMovil from '../../img/header/BeOpenMovil.svg'

import LogoBPM  from '../../img/BPM.png';
import {
    Link, Route
} from "react-router-dom"


const HeaderContainer = (props) =>{
    let ubicacionPrincipal  = window.pageYOffset;
    window.onscroll = function() {
        let Desplazamiento_Actual = window.pageYOffset;
        if((ubicacionPrincipal >= Desplazamiento_Actual)){
            document.getElementById('navbar').style.top = '0';
            setOpen(false)
        }
        else{
            document.getElementById('navbar').style.transition = '1s';
            document.getElementById('navbar').style.top = '-10vh';
        }


        if(window.scrollY<800){
            document.getElementById('navbar').style.top = '0';
        }
        ubicacionPrincipal = Desplazamiento_Actual;
    }


    const[Menu, setMenu] = useState('0')
    const [isOpen, setOpen] = useState(false)
    const [titulos, setTitulos] = useState('hidden')

    function changeMenu(id){
        if(id=='0'){
            setTitulos('visible')
            setMenu('1')
        }
        if(id=='1'){
            setMenu('0')
        }
    }


    return(
            <div>
                {
                    props.name == 'normal'?
                        Menu == '0'  ? 
                        (
                        <div className="HeaderNavContainer" id="navbar">                                
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE"  >
                                
                                        <a href="/" className="LinkLogo"   >
                                            
                                            <img src={LogoBPM} className="logoBPM" />
                                            {/*  <Logo color="#FFF" className='Logo'></Logo>   */}
                                        </a>                                                           
                                    </div>
                                    <div className="containerLinks">      
                                        <div className="imagen"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                            <img  src={IconInstaMovil}  />                                                                 
                                            </a>
                                        </div>                                      
                                        <div className="imagen"> 
                                            <a href='https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0' target='_blank'>                                        
                                            <img  src={IconWhatsMovil}  />    
                                            </a>
                                        </div>
                                        <div className="imagenMovil"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                                <img  src={IconInstaMovil}  />                                                          
                                            </a>
                                        </div>
                                        <div className="imagenMovil"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                                <img  src={IconWhatsMovil}  />                                                          
                                            </a>
                                        </div>
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} >
                                        <Hamburger toggled={isOpen} toggle={setOpen} ></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders" style={{
                                    visibility: titulos
                                }}>
                                    <div className="elementOne"></div>
                                    <div className="elementTwo"></div>
                                    <div className="elementThree">
                                        <ul>
                                            <li ><a href="/servicios/#sectionTwo" className="Links" >What we do</a></li>
                                            <li><a href="/projects" className="Links">Work</a></li>
                                            <li><a href="/contact" className="Links">Contact</a></li>
                                        </ul>
                                        <div>         
                                               
                                        </div>
                                    </div>              
                                </div>
                            </div>                     
                        </div>
                        )
                        : 
                        <div  className="HeaderNavContainerOpen" id="navbar">            
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE" >
                                        <a href="/" className="LinkLogo">
                                            <img src={LogoBPM} className="logoBPM" />
                                        </a>                     
                                    </div>
                                    <div className="containerLinks">
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} >
                                        <Hamburger toggled={isOpen} toggle={setOpen}></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders" >
                                    <div className="elementOne"></div>
                                    <div className="elementTwo"></div>
                                    <div className="elementThree">
                                    <ul>
                                        <li><a href="/servicios/#sectionTwo" className="Links" onClick={()=>changeMenu(Menu)}>What we do</a></li>
                                        <li><a href="/projects" className="Links">Work</a></li>
                                        <li><a href="/contact" className="Links">Contact</a></li>
                                    </ul>
                                    <div>         
                                        <div className="imagen"> 
                                            <a href='https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0' target='_blank'>                                        
                                                <img src={IconWhatsMovil} style={{width:'180%', marginTop:'10%'}}></img>
                                            </a>
                                        </div>      
                                        <div className="imagen"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                            <img src={InstaOpenMovil} width="80%"></img>      
                                            </a>
                                        </div>
                                        <div className="imagen"> 
                                            <a href='https://www.behance.net/tbebranding' target='_blank'>                                        
                                            <img src={BeOpenMovil}></img> 
                                            </a>
                                        </div>     
                                        <div className="imagenMovil"> 
                                            <a href='https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0' target='_blank'>                                        
                                                <img src={IconWhatsMovil} style={{width:'180%', marginTop:'10%'}}></img>
                                            </a>
                                        </div>     
  
                                        <div className="imagenMovil"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                              <img src={InstaOpenMovil} width="80%"></img>                                    
                                            </a>
                                        </div>
                                        <div className="imagenMovil"> 
                                            <a href='https://www.behance.net/tbebranding' target='_blank'>                                        
                                            <img src={BeOpenMovil}></img> 
                                            </a>
                                        </div>     
                                    </div>
                                </div>                            
                            </div>
                        </div>      
                        <div className="filtro"></div>               
                    </div>            
                    :''
                }















{
                    props.name == 'normalWhite'?
                        Menu == '0'  ? 
                        (
                        <div className="HeaderNavContainer" id="navbar">                                
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE"  style={{
                                        backgroundColor:"#FFF"
                                    }}
                                    >
                                
                                        <a href="/" className="LinkLogo" >
                                            <img src={LogoBPM} className="logoBPM" />
                                        </a>                                                           
                                    </div>
                                    <div className="containerLinks" style={{
                                        backgroundColor:"#FFF"
                                    }}>      
                                        <div className="imagen"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                            <img  src={IconInstaMovilBlack}  />       
                                            </a>
                                        </div>
                                        <div className="imagen"> 
                                            <a href='https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0' target='_blank'>                                        
                                            <img  src={IconWhatsMovilBlack}  />  
                                            </a>
                                        </div>
                                        <div className="imagenMovil"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                                <img  src={IconInstaMovilBlack}  />                                                          
                                            </a>
                                        </div>
                                        <div className="imagenMovil"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                                <img  src={IconWhatsMovilBlack}  />                                                          
                                            </a>
                                        </div>
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} 
                                    style={{
                                        backgroundColor:"#FFF"
                                    }}>
                                        <Hamburger toggled={isOpen} color="#000" toggle={setOpen} ></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders" />
                            </div>                     
                        </div>
                        )
                        : 
                        <div  className="HeaderNavContainerOpen" id="navbar">            
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE" >
                                        <a href="/" className="LinkLogo">
                                            <img src={LogoBPM} className="logoBPM" />
                                        </a>                     
                                    </div>
                                    <div className="containerLinks">
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} >
                                        <Hamburger toggled={isOpen} toggle={setOpen}></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders">
                                    <div className="elementOne"></div>
                                    <div className="elementTwo"></div>
                                    <div className="elementThree">
                                    <ul>
                                        <li><a href="/servicios/#sectionTwo" className="Links">What we do</a></li>
                                        <li><a href="/projects" className="Links">Work</a></li>
                                        <li><a href="/contact" className="Links">Contact</a></li>
                                    </ul>
                                    <div>         
                                        <div className="imagen"> 
                                            <a href='https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0' target='_blank'>                                        
                                            <img src={IconWhatsMovil} style={{width:'180%', marginTop:'10%'}}></img>
                                            </a>
                                        </div>      
                                        <div className="imagen"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                            <img src={InstaOpenMovil} width="80%"></img>                                                         
                                            </a>
                                        </div>
                                        <div className="imagen"> 
                                            <a href='https://www.behance.net/tbebranding' target='_blank'>                                        
                                            <img src={BeOpenMovil}></img> 
                                            </a>
                                        </div>     
                                        <div className="imagenMovil"> 
                                            <a href='https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0' target='_blank'>                                        
                                                <img src={IconWhatsMovil} style={{width:'180%', marginTop:'10%'}}></img>
                                            </a>
                                        </div>     
  
                                        <div className="imagenMovil"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                              <img src={InstaOpenMovil} width="80%"></img>                                    
                                            </a>
                                        </div>
                                        <div className="imagenMovil"> 
                                            <a href='https://www.behance.net/tbebranding' target='_blank'>                                        
                                            <img src={BeOpenMovil}></img> 
                                            </a>
                                        </div>     
                                    </div>
                                </div>                            
                            </div>
                        </div>      
                        <div className="filtro"></div>               
                    </div>            
                    :''
                }









{

                    props.name == 'SecondHeader'?
                        Menu == '0'  ? 
                        (
                        <div className="HeaderNavContainer" id="navbar">                                
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE"  
                                    >
                                
                                        <a href="/" className="LinkLogo"  >
                                            <img src={LogoBPM} className="logoBPM" />
                                        </a>                                                           
                                    </div>
                                    <div className="containerLinks">      
                                        <div className="imagen"> 
                                        <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                            <IconInsta color="#FFF" ancho='33.6' alto='33.6'></IconInsta>                                                          
                                        </a>
                                        </div>
                                        <div className="imagen"> 
                                        <a href='https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0' target='_blank'>                                        
                                        <IconWhats color="#FFF" className='imgen'></IconWhats>
                                        </a>
                                        </div>
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} >
                                        <Hamburger toggled={isOpen} toggle={setOpen} ></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders" />
                            </div>                     
                        </div>
                        )
                        : 
                        <div  className="HeaderNavContainerOpen" id="navbar">            
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE" >
                                        <a href="/" className="LinkLogo">
                                            <img src={LogoBPM} className="logoBPM" />
                                        </a>                     
                                    </div>
                                    <div className="containerLinks">
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} >
                                        <Hamburger toggled={isOpen} toggle={setOpen}></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders">
                                    <div className="elementOne"></div>
                                    <div className="elementTwo"></div>
                                    <div className="elementThree">
                                    <ul>
                                        <li><a href="/servicios/#sectionTwo" className="Links">What we do</a></li>
                                        <li><a href="/projects" className="Links">Work</a></li>
                                        <li><a href="/contact" className="Links">Contact</a></li>
                                    </ul>
                                    <div>         
                                        <div className="imagen"> 
                                            <a href='https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0' target='_blank'>                                        
                                                <IconWhats color="#FFF" ></IconWhats>
                                            </a>
                                        </div>      
                                        <div className="imagen"> 
                                            <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                                <IconInsta color="#FFF" ancho='33.6' alto='33.6'></IconInsta>                                                          
                                            </a>
                                        </div>
                                        <div className="imagen"> 
                                            <a href='https://www.behance.net/tbebranding' target='_blank'>                                        
                                                <IconBe back='#FFF' fill='#FF4700'></IconBe>
                                            </a>
                                        </div>     
                                    </div>
                                </div>                            
                            </div>
                        </div>      
                        <div className="filtro"></div>               
                    </div>            
                    :''
                }















{
                    props.name == 'white'?
                        Menu == '0'  ? 
                        (
                        <div className="HeaderNavContainerWhite" id="navbar">     
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE"  
                                    >
                                        <a href="/" className="LinkLogo">
                                            <img src={LogoBPM} className="logoBPM" />
                                        </a>                        
                                    </div>
                                    <div className="containerLinks">     
                                        <div className="imagen"> 
                                        <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                        <IconInsta color="#000" relleno='#FFF' ancho='33.6' alto='33.6'></IconInsta>  
                                        </a>
                                        </div>
                                        <div className="imagen"> 
                                            <IconWhats color="#000"></IconWhats>
                                        </div>                          
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} >
                                        <Hamburger color="#000"></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders" />
                            </div>                     
                        </div>
                        )
                        : 
                        <div  className="HeaderNavContainerOpenWhite" id="navbar">            
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE" >
                                        <a href="/" className="LinkLogo">
                                            <img src={LogoBPM} className="logoBPM" />
                                        </a>                                  
                                    </div>
                                    <div className="containerLinks">
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} >
                                        <Hamburger ></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders">
                                    <div className="elementOne"></div>
                                    <div className="elementTwo"></div>
                                    <div className="elementThree">
                                    <ul>
                                        <li><a href="/servicios/#sectionTwo" className="Links">What we do</a></li>
                                        <li><a href="/projects" className="Links">Work</a></li>
                                        <li><a href="/contact" className="Links">Contact</a></li>
                                    </ul>
                                    <div>
                                        <div className="imagen">
                                        <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                        <IconInsta color="#000" relleno='#FFF' ancho='38.6' alto='38.6'></IconInsta>  
                                        </a>
                                        </div>                              
                                        <div className="imagen">
                                        <IconFace></IconFace>
                                        </div>                              
                                        <div className="imagen">
                                        <IconBe></IconBe>                                        
                                        </div>                         
                                    </div>
                                </div>                            
                            </div>
                        </div>                     
                    </div>            
                    :''
                }

                


                {
                    props.name == 'black'?
                        Menu == '0'  ? 
                        (
                        <div className="HeaderNavContainerBlack" id="navbar">                                
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE"  >
                                        <a href="/" className="LinkLogo">
                                             <img src={LogoBPM} className="logoBPM" />
                                        </a>    
                                    </div>
                                    <div className="containerLinks">                        
                                        <div className="imagen"> 
                                        <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                        <IconInsta color="#FFF" relleno='#000' ancho='33.6' alto='33.6'></IconInsta>  
                                        </a>
                                        </div>
                                        <div className="imagen"> 
                                            <IconWhats color="#FFF"></IconWhats>
                                        </div>
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} >
                                        <Hamburger></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders" />
                            </div>                     
                        </div>
                        )
                        : 
                        <div  className="HeaderNavContainerOpenBlack" id="navbar">            
                            <div className="navigationMenu">
                                <div className="MenuBottoms">
                                    <div className="containerTBE" >                                        
                                        <a href="/" className="LinkLogo">
                                            <img src={LogoBPM} className="logoBPM" />
                                        </a>                                            
                                    </div>
                                    <div className="containerLinks">
                                    </div>
                                    <div className="containerBotton" onClick={()=>changeMenu(Menu)} >
                                        <Hamburger ></Hamburger>
                                    </div>                        
                                </div>
                                <div className="MenuTitleHeaders">
                                    <div className="elementOne"></div>
                                    <div className="elementTwo"></div>
                                    <div className="elementThree">
                                    <ul>
                                        <li><a href="/servicios/#sectionTwo" className="Links">What we do</a></li>
                                        <li><a href="/projects" className="Links">Work</a></li>
                                        <li><a href="/contact" className="Links">Contact</a></li>
                                    </ul>
                                    <div>
                                        <div className="imagen">
                                        <a href='https://www.instagram.com/tbestudio_/' target='_blank'>
                                        <IconInsta color="#FFF" relleno='#000' ancho='38.6' alto='38.6'></IconInsta>  
                                        </a>
                                        </div>                              
                                        <div className="imagen">
                                        <IconFace></IconFace>
                                        </div>                              
                                        <div className="imagen">
                                        <IconBe></IconBe>                                        
                                        </div>                                                         
                                    </div>
                                </div>                            
                            </div>
                        </div>                     
                    </div>            
                    :''
                }

            
        </div>  
    )
}
export default HeaderContainer














