import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import './index.scss'


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailone/newImages/Bart Street_ReBranding_01.jpg';
import ImgTwo from'../../../img/details/detailone/newImages/Bart Street_ReBranding_02.jpg';
import ImgThree from '../../../img/details/detailone/newImages/Bart Street_ReBranding_03.jpg';
import ImgFour from '../../../img/details/detailone/newImages/Bart Street_ReBranding_04.jpg';
import ImgFive from '../../../img/details/detailone/newImages/Bart Street_ReBranding_05.jpg';
import ImgSix from '../../../img/details/detailone/newImages/Bart Street_ReBranding_06.jpg';
import Brandin from '../../../img/details/detailone/newImages/Bart Street_ReBranding_07.jpg';
 
import ImgEight from '../../../img/details/detailone/newImages/Bart Street_ReBranding_08.jpg';
import ImgNine from '../../../img/details/detailone/newImages/Bart Street_ReBranding_09.jpg';
import ImgTen from '../../../img/details/detailone/newImages/Bart Street_ReBranding_010.jpg';
import ImgEleven from '../../../img/details/detailone/newImages/Bart Street_ReBranding_011.jpg';


import ImgTwelve from '../../../img/details/detailone/newImages/Bart Street_ReBranding_012.jpg';
import ImgTheerteen from '../../../img/details/detailone/newImages/Bart Street_ReBranding_013.jpg';
import ImgFourteen from '../../../img/details/detailone/newImages/Bart Street_ReBranding_014.jpg';
import ImgFiveteen from '../../../img/details/detailone/newImages/Bart Street_ReBranding_015.jpg';



import ImgSixteen from '../../../img/details/detailone/newImages/Bart Street_ReBranding_016.jpg';
import ImgSeventeen from '../../../img/details/detailone/newImages/Bart Street_ReBranding_017.jpg';
import ImgEightteen from '../../../img/details/detailone/newImages/Bart Street_ReBranding_018.jpg';
import ImgNineTeen from '../../../img/details/detailone/newImages/Bart Street_ReBranding_019.jpg';
import ImgTwenty from '../../../img/details/detailone/newImages/Bart Street_ReBranding_020.jpg';
import ImgTwentyOne from '../../../img/details/detailone/newImages/Bart Street_ReBranding_021.jpg';
import ImgTwentyTwo from '../../../img/details/detailone/newImages/Bart Street_ReBranding_022.jpg';
import ImgTwentyThree from '../../../img/details/detailone/newImages/Bart Street_ReBranding_023.jpg';
import ImgTwentyFour from '../../../img/details/detailone/newImages/Bart Street_ReBranding_024.jpg';






import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectOne = () => {
  
        setTimeout(function(){
            let element = document.getElementById("ProjectOne");
            element.scrollIntoView({block: "start", behavior: "smooth"})
        }, 400);
    
   

    return(
    <div className="containerDetailOne" id="ProjectOne">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects" >
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


         
            <div className="Video">                
                    <ReactPlayer
                    url='https://youtu.be/i_jQG65iGy0'
                    className='react-player'
                    playing
                    width='100%'
                    min-height='100%'
                    controls= 'true'

                    />                
            </div>   
           
        
      

            <div className="ContentProjectsViewImgageOneDetailOne">
                <div className="ContentProjectsViewImgageLeft">
                    <img src={ImgOne} width='100%'></img>
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">Re-Branding</text>
                <text>
                    <label>THE BRAND</label>
                    The Bart Street vision is to bring street art into people's 
                    lives while supporting creators,  with the goal to be the premier 
                    platform for emerging artists to bring their art to life thru the lifestyle
                    products that Bart Street produces with the collaborator's art styles.
                </text>
                <text>
                    <label>THE MISSION</label>
                    Our mission was to take Bart Street to the next level designing a lifestyle brand which serves as a canvas for creators. We knew that the logo needed a memorable element that was as powerful and iconic as it was flexible and adaptable.
                </text>
            </div>    

         



            <div className="ContentProjectsViewImgageTwoDetailOne">                
                <div className="ContentProjectsViewImgageRight">
                     <img src={ImgTwo} width='100%'></img>
                </div>
            </div>  


        

            

            <div className="ContentProjectsViewImgageOneDetailOne">
                <div className="ContentProjectsViewImgageLeft">             
                <img src={ImgThree} width='100%'></img>
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailOne">
               
                <div className="ContentProjectsViewImgageRight">
                    <img src={ImgFour} width='100%'></img>
                </div>
            </div>    


            <div className="ContentProjectsViewText">                
                
                <text>
                    <label>BART APPLICATIONS</label>
                    The Bart Street Visionis to bring street art into people's 
                    lives while supporting creators. The goal is to be the premier 
                    platform for emerging artists to bring their art to life thru the lifestyle
                    products that Bart Street produces with the collaborator's art styles.
                </text>
                
            </div>    

            <div className="ContentProjectsViewImgageTwoDetailOne">                
                <div className="ContentProjectsViewImgageRight">
                     <img src={Brandin} width='100%'></img>
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailOne">
                <div className="ContentProjectsViewImgageLeft"

                >                               
                   <img src={ImgFive} width='100%'></img>
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailOne">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                    <img src={ImgSix} width='100%'></img>
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailOne">
              
                <div className="ContentProjectsViewImgageRight">
                    <img src={ImgEight} width='100%'></img>
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailOne">
              
                <div className="ContentProjectsViewImgageRight">
                    <img src={ImgNine} width='100%'></img>
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailOne">
              
                <div className="ContentProjectsViewImgageRight">
                    
                    <img src={ImgTen} width='100%'></img>
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgEleven})`
                    }}
                >
                </div>
            </div>  



            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgTwelve})`
                    }}
                >
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgTheerteen})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgFourteen})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgFiveteen})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgSixteen})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgSeventeen})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgEightteen})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgNineTeen})`
                    }}
                >
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgTwenty})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgTwentyOne})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgTwentyTwo})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgTwentyThree})`
                    }}
                >
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailOne">              
                <div className="ContentProjectsViewImgageRight"
                    style={{
                        backgroundImage:  `url(${ImgTwentyFour})`
                    }}
                >
                </div>
            </div>  


        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectOne;