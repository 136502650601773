	
import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import './index.scss'
import './ipad.scss'
import './mobile.scss'
import Flecha from '../../img/flecha'
import Gif from '../../img/poppup/giphy.gif'

import Pop from '../popup'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


//sara@kasamasaryk.com
  

const FormContact = () => {
   const frmContact = { reply_to:'vladi_ortega@hotmail.com', 
                        from_name:'',
                        userEmail:'', 
                        concernCategory:'',     
                        emailTitle:'', 
                        emailDetails:'' ,



                        name:'',
                        lastname:'',
                        email:'',
                        cell:'',
                        company:'',
                        taxID:'',
                        brand:'',
                        industry:'',
                        country:'',
                        message:'Tell us a little about what you need : ',
                        start:'',
                        end:'',
                        brandig:'',
                        graphic:'',
                        web:''
    };
   const [contact,setContact] = useState(frmContact);
   const [showMessage, setShowMessage] = useState(false);

   const [branding, setBrandign] = useState('')
   const [graphic, setGraphic] = useState('')
   const [web, setWeb] = useState('')


   const handleChange = e => { 
		const {name,value} = e.target;
		setContact({...contact,[name]:value}); 
   };
   const handleSubmit = e =>{
	    e.preventDefault();
	   
		emailjs.send('default_service','template_ammjr2i', contact,'akEUA8UHaQ1B4AlAf')
		.then((response) => {
				   console.log('SUCCESS!', response.status, response.text);
				   setContact(frmContact);
				   setShowMessage(true);
                   
                    setTimeout(function(){
                        window.location.reload()
                    }, 1000);
           
		}, (err) => {
				   console.log('FAILED...', err);
		});
   }



    //const contentStyle = { background: '#000' };
    //const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
    //const arrowStyle = { color: '#fff' }; // style for an svg element
  return (
      <div >
    <div className="containerFormContact" id='containerFormContact'  >


            <div className="formTitle">
                <div className="formTitleLeft">
                    Get in touch
                </div>
                <div className="formTitleRight">
                    A small step for man. <br />
                    A giant leap for your startup.
                </div>
            </div>
            	
		<form onSubmit={handleSubmit}>
            <div className="containerFormContent" >
                <div className="containerFormContentLeft" >                    


                    <div className="subtitleForm">
                        <text> Full Name / <tex  className='gray'>Nombre Completo </tex></text>
                    </div>
                    <div className="inputForm">
                        <input type="text" value={contact.name}  name="country" onChange={handleChange} placeholder="Thomas Edwhart"></input>
                    </div>
                    
                 
                    <div className="subtitleForm">
                        <text>Email / <tex  className='gray'>Correo </tex></text>
                        <text>Phone / <tex  className='gray'>Tel</tex></text>
                    </div>
                    <div className="inputForm">
                        <input type="text"  value={contact.email}  name="email" onChange={handleChange}  placeholder="email@example.com"></input>
                        <input type="text"  value={contact.cell}  name="cell" onChange={handleChange}   placeholder="+1(333)333-3333"></input>
                    </div>
{/*
                    <div className="subtitleForm">
                        <text>Company  /<tex  className='gray'> Empresa</tex> </text>
                        <text>Industry / <tex  className='gray' > Industria</tex></text>
                    </div>
                    <div className="inputForm">
                        <input type="text"  value={contact.company}  name="company" onChange={handleChange}     placeholder="Example"></input>
                        <input type="text"  value={contact.industry}  name="industry" onChange={handleChange}    placeholder="Example"></input>
                    </div>

  */}
                  {/*
                    <div className="subtitleForm">
                        <text> Brand / <tex  className='gray'> Marca </tex></text>
                        <text> Industry /<tex  className='gray'> Industria</tex></text>
                    </div>
  
                    <div className="inputForm">
                        <input type="text" value={contact.brand}  name="brand" onChange={handleChange}  placeholder="Example"></input>
                        <input type="text" value={contact.industry}  name="industry" onChange={handleChange}  placeholder="Example"></input>
                    </div>
                    
  */}
                    <div className="subtitleForm">
                        <text> Country  / <tex  className='gray'>Pais </tex></text>
                    </div>
                    <div className="inputForm">
                        <input type="text" value={contact.country}  name="country" onChange={handleChange} placeholder="USA"></input>
                    </div>
                    
                    <div className="subtitleForm">
                        <text> How can we help you ? /<tex  className='gray'> Como podemos ayudarte</tex> </text>
                    </div>
                    <div className="inputForm">
                        <textarea className="textarea" rows="5" cols="80" value={contact.message}  name="message" onChange={handleChange}>
                        </textarea>
                    </div>

                    

                    {/*
                    <div className="subtitleForm">
                        <text> Services required / <tex className='gray'>Servicios requeridos</tex> </text>
                    </div>
                    <br></br>
                    <div className="inputRadius">                        
                        <div>
                            <label className="title">Branding</label>
                            <label>
                                <input type="checkbox" id='radio1' />
                                Naming
                            </label>
                            <label>
                                <input type="checkbox"></input>
                                New Brand
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Rebrand
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Brand applications
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Brand Book / Visual Identity
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Social Media Assets
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Packaging
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Signage
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Naming System
                            </label>
                        </div>
                        <div>
                            <label className="title">Graphic Design</label>
                            <label>
                            <input type="checkbox"></input>
                                Advertisement
                            </label>
                            <label>
                                <input type="checkbox"></input>
                                Presentation
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Catalog
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Infographic
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Illustration
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Iconography
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Photo manipulation
                            </label>
                           
                        </div>
                        <div>
                            <label className="title"> Web Design & Development</label>
                            <label>
                            <input type="checkbox"></input>
                                UI Design
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                UX Design
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Front End Development
                            </label>
                        </div>
                    </div>
                */}            









                    <Popup className='popContainer' id="contenPopContainer"
                        trigger={<button type="submit" className="Boton"><text>Submit</text> <Flecha /></button>}
                        nested   
                        style={{backgroundColor:"red",display:"flex",justifyContent:"center"}}
                    >
                        <div className='containerPop'  id='containerPop' style={{
                            display:"flex",alignItems:"center",
                            margin:"0",padding:"0"
                        }}>
                            <img src={Gif} width="100%" ></img>
                        </div>
                    
                    </Popup>















{/*
                    <button type="submit" className="Boton"><text>Submit</text> <Flecha /></button>        
*/}



                     
                </div>
                <div className="containerFormContentRight">
                    <text>
                    Let’s build something great together !
                    </text>
                </div>

            </div>
        </form>

    </div>










        
    <div className="containerFormContactMovil" id='containerFormContactMovil' >
        <div className="formTitle">
                <div className="formTitleLeft">
                    Get in touch
                </div>
                <div className="formTitleRight">
                Let’s build something<br></br> great together !
                </div>
            </div>
            	
		<form onSubmit={handleSubmit}>
            <div className="containerFormContent">
                <div className="containerFormContentLeft">                    
                    <div className="subtitleForm">
                        <text className="LeftSubtitle">Full Name / <tex  className='gray'> Nombre Completo </tex></text>
                        <input type="text" value={contact.name}  name="name" onChange={handleChange}  placeholder="Thomas"></input>
                    </div>
                

                    <div className="subtitleForm">
                        <text>Email / <tex  className='gray'>Correo </tex></text>
                        <input type="text"  value={contact.email}  name="email" onChange={handleChange}  placeholder="email@example.com"></input>
                    </div>
                    <div className="inputForm">                        
                        <text>Phone / <tex  className='gray'>Tel</tex></text>
                        <input type="text"  value={contact.cell}  name="cell" onChange={handleChange}   placeholder="+1(333)333-3333"></input>
                    </div>

                 
                  {/*
                    <div className="subtitleForm">
                        <text> Brand / <tex  className='gray'> Marca </tex></text>
                        <input type="text" value={contact.brand}  name="brand" onChange={handleChange}  placeholder="Example"></input>
                    </div>
                   
                    <div className="inputForm">
                        <text> Industry /<tex  className='gray'> Industria</tex></text>                       
                        <input type="text" value={contact.industry}  name="industry" onChange={handleChange}  placeholder="Example"></input>
                    </div>
                     */}

                    <div className="subtitleForm">
                        <text> Country  / <tex  className='gray'>Pais </tex></text>
                    </div>
                    <div className="inputForm">
                        <input type="text" value={contact.country}  name="country" onChange={handleChange} placeholder="USA"></input>
                    </div>
                    
                    <div className="subtitleForm">
                        <text> how can we help you ? /<tex  className='gray'> Como podemos ayudarte</tex> </text>
                    </div>
                    <div className="inputForm">
                        <textarea className="textarea" rows="5" cols="80" value={contact.message}  name="message" onChange={handleChange}>
                            
                        </textarea>
                    </div>


                    {/*
                    <div className="subtitleForm">
                        <text> Services required / <tex className='gray'>Servicios requeridos</tex> </text>
                    </div>
                    <br></br>
                    <div className="inputRadius">                        
                        <div>
                            <label className="title">Branding</label>
                            <label>
                                <input type="checkbox" id='radio1' />
                                Naming
                            </label>
                            <label>
                                <input type="checkbox"></input>
                                New Brand
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Rebrand
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Brand applications
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Brand Book / Visual Identity
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Social Media Assets
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Packaging
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Signage
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Naming System
                            </label>
                        </div>
                        <div>
                            <label className="title">Graphic Design</label>
                            <label>
                            <input type="checkbox"></input>
                                Advertisement
                            </label>
                            <label>
                                <input type="checkbox"></input>
                                Presentation
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Catalog
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Infographic
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Illustration
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Iconography
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Photo manipulation
                            </label>
                           
                        </div>
                        <div>
                        <label className="title"> Web Design & Development</label>
                            <label>
                            <input type="checkbox"></input>
                                UI Design
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                UX Design
                            </label>
                            <label>
                            <input type="checkbox"></input>
                                Front End Development
                            </label>
                        </div>
                    </div>
                    */}


                    <Popup className='popContainer'
                        trigger={<button type="submit" className="Boton"><text>Submit</text> <Flecha /></button>}
                        modal
                        nested   
                        
                    >
                        <div className='containerPop' style={{
                            border:"1px solid red", display:"flex",alignItems:"center",
                            margin:"0",padding:"0"
                        }}>
                            <img src={Gif} width="100%"></img>
                        </div>
                    
                    </Popup>


                    {
                    //<button type="submit" className="Boton"><text>Submit</text> <Flecha /></button>
                    }
                </div>
                <div className="containerFormContentRight">
                Let’s build something great together !
                </div>

            </div>
        </form>
    </div>

</div>

  )}



export default FormContact;


