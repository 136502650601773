import React from "react";
import './index.scss'
import './ipad.scss'
import './mobile.scss'
import { useState } from "react";
import elipse from '../../img/pagetwo/Elipse.svg';


import gsap from 'gsap'


import Menos from '../../img/pagethree/Menos.svg';
import Mas from '../../img/pagethree/Mas.svg';

import  Flecha from '../../../components/img/flecha'
import { Link } from "react-router-dom";
const PageTwo = () =>{
    const [option, setOption] = useState('0')

    const [submenu1, setSubmenu1] = useState('0')
    const [submenu2, setSubmenu2] = useState('0')
    const [submenu3, setSubmenu3] = useState('0')
    const [submenu4, setSubmenu4] = useState('0')

    function Cambiarsubmenu1(id){
        if(submenu1=='0'){
            setSubmenu1(id)
            setSubmenu2('0')
            setSubmenu3('0')
            setSubmenu4('0')
        }else{
            setSubmenu1('0')
            setSubmenu2('0')
            setSubmenu3('0')
            setSubmenu4('0')
        }
    }
    function Cambiarsubmenu2(id){
        if(submenu2=='0'){
            setSubmenu1('0')
            setSubmenu2(id)
            setSubmenu3('0')
            setSubmenu4('0')
        }else{
            setSubmenu1('0')
            setSubmenu2('0')
            setSubmenu3('0')
            setSubmenu4('0')
        }
    }
    function Cambiarsubmenu3(id){
        if(submenu3=='0'){
            setSubmenu1('0')
            setSubmenu2('0')
            setSubmenu3(id)
            setSubmenu4('0')
        }else{
            setSubmenu1('0')
            setSubmenu2('0')
            setSubmenu3('0')
            setSubmenu4('0')
        }
    }
    function Cambiarsubmenu4(id){
        if(submenu4=='0'){
            setSubmenu1('0')
            setSubmenu2('0')
            setSubmenu3('0')
            setSubmenu4(id)
        }else{
            setSubmenu1('0')
            setSubmenu2('0')
            setSubmenu3('0')
            setSubmenu4('0')
        }
    }
    //id='containerContentPageTwo'
    return(
        <div className="containerContentPageTwo" >
            <div className="titlePageTwo">
                <img loading="lazy" src={elipse} alt="React Logo" width="15vw"/>
                Our Values               
            </div>
            <div className="containerSubContentPageTwo">
                <div className="subtitleContainerPageTwo">
                    WE SHARE YOUR GOALS 
                    WE BELIEVE IN YOUR SUCCESS.
                </div>







                <div className="optionSelectSubtitle">
                    <div className="submenuPageTwo">
                            {
                                option == 0 ? (
                                <ul className="submenu">
                                    <li onClick={()=>{setOption('0')}} className="suboptionSelected">
                                        <text>TIME</text>
                                        <div className="textContainerDesplegado">
                                            <div className="titleDesplegado">Quick turn around</div>
                                            <div className="subtitleDesplegado">TIME IS MONEY</div>
                                            <div className="textDesplegado">
                                                Your project completed on schedule means reaching your goals faster.
                                                See better result in less time.
                                            </div>
                                            <Link to="/contact" className="LinksDesplegado">  
                                                <div className="boton"><text>Ask for a quote </text><Flecha></Flecha></div>
                                            </Link>
                                        </div>
                                    </li>
                                    <li onClick={()=>{setOption('1')}}><text>QUALITY</text></li>
                                    <li onClick={()=>{setOption('2')}}><text>SERVICE</text></li> 
                                    <li onClick={()=>{setOption('3')}}><text>MONEY</text></li>
                                </ul>
                                ):''
                            }
                            {
                                option == 1 ? (
                                <ul className="submenu">
                                    <li onClick={()=>{setOption('0')}} ><text>TIME</text></li>
                                    <li onClick={()=>{setOption('1')}} className="suboptionSelected">
                                        <text>QUALITY</text>
                                        <div className="textContainerDesplegado">
                                            <div className="titleDesplegado"> Outstanding quality</div>
                                            <div className="subtitleDesplegado">QUALITY IS EVERYTHING</div>
                                            <div className="textDesplegado">
                                                Unparalleled attention to detail and superior
                                                quality in every aspect. Take your business to the next level.
                                            </div>
                                            <Link to="/contact" className="LinksDesplegado"> 
                                                <div className="boton"><text>Ask for a quote </text><Flecha></Flecha></div>
                                            </Link>
                                        </div>
                                    
                                    </li>
                                    <li onClick={()=>{setOption('2')}}><text>SERVICE</text></li> 
                                    <li onClick={()=>{setOption('3')}}><text>MONEY</text></li>
                                </ul>
                                ):''
                            }
                            {
                                option == 2 ? (
                                <ul className="submenu">
                                    <li onClick={()=>{setOption('0')}}><text>TIME</text></li>
                                    <li onClick={()=>{setOption('1')}}><text>QUALITY</text></li>
                                    <li onClick={()=>{setOption('2')}} className="suboptionSelected">
                                        <text>SERVICE</text>
                                        <div className="textContainerDesplegado">
                                            <div className="titleDesplegado"> Premium service</div>
                                            <div className="subtitleDesplegado">SERVICE THAT SHOWS WE CARE</div>
                                            <div className="textDesplegado">
                                            From initial contact to project completion, we're always available with great service, timely responses and an upbeat attitude. Give your project the attention it deserves.
                                            </div>
                                            <Link to="/contact" className="LinksDesplegado">  
                                                <div className="boton"><text>Ask for a quote </text><Flecha></Flecha></div>
                                            </Link>
                                        </div>
                                    
                                    </li> 
                                    <li onClick={()=>{setOption('3')}}><text>MONEY</text></li>
                                </ul>
                                ):''
                            }
                            {
                                option == 3 ? (
                                <ul className="submenu">
                                    <li onClick={()=>{setOption('0')}} ><text>TIME</text></li>
                                    <li onClick={()=>{setOption('1')}} ><text>QUALITY</text></li>
                                    <li onClick={()=>{setOption('2')}}><text>SERVICE</text></li> 
                                    <li onClick={()=>{setOption('3')}} className="suboptionSelected">
                                        <text>MONEY</text>
                                        <div className="textContainerDesplegado">
                                            <div className="titleDesplegado">Exceptional value</div>
                                            <div className="subtitleDesplegado">MORE FOR YOUR INVESTMENT</div>
                                            <div className="textDesplegado">
                                                With all-inclusibve branding packages, you know
                                                you're getting the best for your money. 
                                                Get a bigger impact at a better value.
                                            </div>
                                            <Link to="/contact" className="LinksDesplegado"> 
                                                <div className="boton"><text>Ask for a quote </text><Flecha></Flecha></div>                                
                                            </Link>
                                                
                                        </div>
                                    </li>
                                </ul>
                                ):''
                            }
                        
                    </div>
                    <div className="contentMenuPageTwo">
                        {
                            option == 0?
                                (<div className="textContainer">
                                    <div className="title">Quick turn around</div>
                                    <div className="subtitle">TIME IS MONEY</div>
                                    <div className="text">
                                        Your project completed on schedule means reaching your goals faster.
                                        See better result in less time.
                                    </div>
                                    <Link to="/contact" className="Links">  
                                        <div className="boton"><text>Ask for a quote </text><Flecha></Flecha></div>
                                    </Link>
                                </div>)
                            :''
                        }
                        {
                            option == 1?
                            (<div className="textContainer">
                                <div className="title"> Outstanding quality</div>
                                <div className="subtitle">QUALITY IS EVERYTHING</div>
                                <div className="text">
                                    Unparalleled attention to detail and superior
                                    quality in every aspect. Take your business to the next level.
                                </div>
                                <Link to="/contact" className="Links"> 
                                    <div className="boton"><text>Ask for a quote </text><Flecha></Flecha></div>
                                </Link>
                            </div>)
                            :''
                        }
                        {
                            option == 2?
                            (<div className="textContainer">
                                <div className="title"> Premium service</div>
                                <div className="subtitle">SERVICE THAT SHOWS WE CARE</div>
                                <div className="text">
                                From initial contact to project completion, we're always available with great service, timely responses and an upbeat attitude. Give your project the attention it deserves.
                                </div>
                                <Link to="/contact" className="Links">  
                                    <div className="boton"><text>Ask for a quote </text><Flecha></Flecha></div>
                                </Link>
                            </div>)
                            :''
                        }
                        {
                            option == 3?
                            (<div className="textContainer">
                                <div className="title">Exceptional value</div>
                                <div className="subtitle">MORE FOR YOUR INVESTMENT</div>
                                <div className="text">
                                    With all-inclusive branding packages, you know
                                    you're getting the best for your money. 
                                    Get a bigger impact at a better value.
                                </div>
                                <Link to="/contact" className="Links"> 
                                    <div className="boton"><text>Ask for a quote </text><Flecha></Flecha></div>                                
                                </Link>
                                    
                            </div>)
                            :''
                        }
                    </div>
                </div>

                <div className="optionSelectSubtitleMovil">
                    <div className="submenuPageTwo">
                            <ul>                                
                                    {
                                        submenu1==1?
                                        <li className="opcionesDesplegadas">
                                            <text onClick={()=>Cambiarsubmenu1(0)}>TIME</text>
                                            <div className="img" onClick={()=>Cambiarsubmenu1(0)}>
                                                <img src={Menos} />
                                            </div>                                                       
                                            <div className="textContainerDesplegado">
                                                <div className="titleDesplegado">Quick turn around</div>
                                                <div className="subtitleDesplegado">TIME IS MONEY</div>
                                                <div className="textDesplegado">
                                                    Your project completed on schedule means reaching your goals faster.
                                                    See better result in less time.
                                                </div>                                        
                                            </div>
                                        </li>   
                                        :
                                        <li className="opcionesEscondido">
                                            <text onClick={()=>Cambiarsubmenu1(1)}>TIME</text>
                                            <div className="img" onClick={()=>Cambiarsubmenu1(1)}>
                                                <img src={Menos} /><img src={Mas} />
                                            </div>                                                       
                                            <div className="textContainerEscondido">
                                                <div className="titleEscondido">Quick turn around</div>
                                                <div className="subtitleEscondido">TIME IS MONEY</div>
                                                <div className="textEscondido">
                                                    Your project completed on schedule means reaching your goals faster.
                                                    See better result in less time.
                                                </div>                                        
                                            </div>
                                        </li>   
                                    }
                                        


                                    {
                                        submenu2==1?
                                        <li className="opcionesDesplegadas">
                                            <text onClick={()=>Cambiarsubmenu2(0)}>QUALITY</text>
                                            <div className="img" onClick={()=>Cambiarsubmenu2(0)}>
                                                <img src={Menos} />
                                            </div>                                                       
                                            <div className="textContainerDesplegado">
                                                <div className="titleDesplegado">Outstanding quality</div>
                                                <div className="subtitleDesplegado">QUALITY IS EVERYTHING.</div>
                                                <div className="textDesplegado">
                                                Unparalleled attention to detail and superior quality in every aspect. 
                                                Take your business to the next level.
                                                </div>                                        
                                            </div>
                                        </li>   
                                        :
                                        <li className="opcionesEscondido">
                                            <text  onClick={()=>Cambiarsubmenu2(1)}>QUALITY</text>
                                            <div className="img" onClick={()=>Cambiarsubmenu2(1)}>
                                                <img src={Menos} /><img src={Mas} />
                                            </div>                                                       
                                            <div className="textContainerEscondido">
                                                <div className="titleEscondido">Outstanding quality</div>
                                                <div className="subtitleEscondido">QUALITY IS EVERYTHING.</div>
                                                <div className="textEscondido">
                                                    Unparalleled attention to detail and superior quality in every aspect. 
                                                    Take your business to the next level.
                                                </div>                                        
                                            </div>
                                        </li>   
                                    }


                                    {
                                        submenu3==1?
                                        <li className="opcionesDesplegadas">
                                            <text onClick={()=>Cambiarsubmenu3(0)}>SERVICE</text>
                                            <div className="img" onClick={()=>Cambiarsubmenu3(0)}>
                                                <img src={Menos} />
                                            </div>                                                       
                                            <div className="textContainerDesplegado">
                                                <div className="titleDesplegado">Premium service</div>
                                                <div className="subtitleDesplegado">SERVICE THAT SHOWS WE CARE</div>
                                                <div className="textDesplegado">
                                                From initial contact to project completion, we're always available with great service, timely responses and an upbeat attitude. Give your project the attention it deserves.
                                                </div>                                        
                                            </div>
                                        </li>   
                                        :
                                        <li className="opcionesEscondido">
                                            <text onClick={()=>Cambiarsubmenu3(1)}>SERVICE</text>
                                            <div className="img" onClick={()=>Cambiarsubmenu3(1)}>
                                                <img src={Menos} /><img src={Mas} />
                                            </div>                                                       
                                            <div className="textContainerEscondido">
                                                <div className="titleEscondido">Premium service</div>
                                                <div className="subtitleEscondido">SERVICE THAT SHOWS WE CARE</div>
                                                <div className="textEscondido">
                                                From initial contact to project completion, we're always available with great service, timely responses and an upbeat attitude. Give your project the attention it deserves.
                                                </div>                                        
                                            </div>
                                        </li>   
                                    }
                                    {
                                        submenu4==1?
                                        <li className="opcionesDesplegadas">
                                            <text onClick={()=>Cambiarsubmenu4(0)}>MONEY</text>
                                            <div className="img" onClick={()=>Cambiarsubmenu4(0)}>
                                                <img src={Menos} />
                                            </div>                                                       
                                            <div className="textContainerDesplegado">
                                                <div className="titleDesplegado">Exceptional value</div>
                                                <div className="subtitleDesplegado">MORE FOR YOUR INVESTMENT.</div>
                                                <div className="textDesplegado">
                                                With all-inclusive branding packages, you know you’re getting 
                                                the best for your money. Get a bigger impact at a better value.
                                                </div>                                        
                                            </div>
                                        </li>   
                                        :
                                        <li className="opcionesEscondido">
                                            <text onClick={()=>Cambiarsubmenu4(1)}>MONEY</text>
                                            <div className="img" onClick={()=>Cambiarsubmenu4(1)}>
                                                <img src={Menos} /><img src={Mas} />
                                            </div>                                                       
                                            <div className="textContainerEscondido">
                                                <div className="titleEscondido">Exceptional value</div>
                                                <div className="subtitleEscondido">MORE FOR YOUR INVESTMENT.</div>
                                                <div className="textEscondido">
                                                With all-inclusive branding packages, you know you’re getting 
                                                the best for your money. Get a bigger impact at a better value.
                                                </div>                                        
                                            </div>
                                        </li>   
                                    }
                               
                            </ul>
                        


                    </div>                           
                </div>
 
            </div>
        </div>
    )
}
export default PageTwo;