import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";
import ReactPlayer from 'react-player'


import iconDirection from '../../../img/projects/Elipse 39.svg';


import ImgOne from '../../../img/details/detailsixteen/1.gif';
import ImgTwo from '../../../img/details/detailsixteen/2.jpg';
import ImgThree from '../../../img/details/detailsixteen/3.jpg';
import ImgFour from '../../../img/details/detailsixteen/4.jpg';
import ImgFive from '../../../img/details/detailsixteen/5.jpg';
import ImgSix from '../../../img/details/detailsixteen/6.jpg';
import Brandin from '../../../img/details/detailsixteen/7.jpg';
 
import ImgEight from '../../../img/details/detailsixteen/8.jpg';




import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >

                        <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%" />                                      
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">HIVEMIND</text>
                <text>
                    <label>THE BRAND</label>
                    Hive Mind provides psilocybin extract, both for therapeutic as well as recreational purposes. Offering these products as drops, chocolates, and dried mushrooms, Hive Mind is on a mission to improve quality of life for users. The company straddles the line between playful and medicinal.                   
                </text>
                <text>
                    <label>THE CHALLENGE</label>
                    Our goal was to establish a brand that is trustworthy and professional, while at the same time maintaining the fun recreational side of this business. We looked to position Hivemind as a benchmark brand  in its industry, recognized for its quality and excellence. 
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  





        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;