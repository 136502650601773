import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";

import ReactPlayer from 'react-player'

import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailseven/RW-1.jpg';
import ImgTwo from '../../../img/details/detailseven/RW-2.jpg';
import ImgThree from '../../../img/details/detailseven/RW-3.jpg';
import ImgFour from '../../../img/details/detailseven/RW-4.jpg';
import ImgFive from '../../../img/details/detailseven/RW-5.gif';
import ImgSix from '../../../img/details/detailseven/RW-6.jpg';
import Brandin from '../../../img/details/detailseven/RW-7.gif';
 
import ImgEight from '../../../img/details/detailseven/RW-8.jpg';
import ImgNine from '../../../img/details/detailseven/RW-9.jpg';
import ImgTen from '../../../img/details/detailseven/RW-10.jpg';
import ImgEleven from '../../../img/details/detailseven/RW-12.jpg';
import ImgTwelve from '../../../img/details/detailseven/RW-13.jpg';

import ImgThirteen from '../../../img/details/detailseven/RW-14.jpg';
import ImgFourteen from '../../../img/details/detailseven/RW-15.jpg';
import ImgFiveteen from '../../../img/details/detailseven/RW-13.jpg';
import ImgSixteen from '../../../img/details/detailseven/RW-16.jpg';
import ImgSeventeen from '../../../img/details/detailseven/RW-17.jpg';
import ImgEighteen from '../../../img/details/detailseven/RW-18.jpg';
import ImgNineteen from '../../../img/details/detailseven/RW-20.gif';

import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>








            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >
                     <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%" />  
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">Revelwear</text>
                <text>
                    <label>THE BRAND</label>
                    Revelwear is a groundbreaking brand in the fashion and wellness industry, providing diabetics with freedom and discretion along with the luxury of being comfortable and fashionable. You no longer have to choose health needs over style.
                </text>
                <text>
                    <label>THE MISSION</label>
                    To bring the brand into the 21st century with a cohesive, memorable and powerful brand that reflects the innovation, quality and style of Revelwear, while also transmitting the message of freedom and wellbeing. We wanted your customer to know that your products aren’t just products they need but they are styles they want.
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgTen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgEleven} alt="React Logo"  width="100%" />  
                </div>
            </div>  
























            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgThirteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgFourteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  





            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgFiveteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSixteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgSeventeen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgEighteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
              <div className="ContentProjectsViewImgageRight"
               
              >
                  <img loading="lazy" src={ImgNineteen} alt="React Logo"  width="100%" />  
              </div>
          </div>  




        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;