import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailfourteen/1_PORTADA.jpg';
import ImgTwo from '../../../img/details/detailfourteen/2.jpg';
import ImgThree from '../../../img/details/detailfourteen/3.jpg';
import ImgFour from '../../../img/details/detailfourteen/4.jpg';
import ImgFive from '../../../img/details/detailfourteen/5.jpg';
import ImgSix from '../../../img/details/detailfourteen/6.jpg';
import Brandin from '../../../img/details/detailfourteen/7.jpg';
 
import ImgEight from '../../../img/details/detailfourteen/8.jpg';
import ImgNine from '../../../img/details/detailten/Img9.jpeg';
import ImgTen from '../../../img/details/detailten/Img10.jpeg';
import ImgEleven from '../../../img/details/detailten/Img11.jpeg';


 
import ImgTwelve from '../../../img/details/detailten/Img12.jpeg';
import ImgTherteen from '../../../img/details/detailten/Img13.jpeg';
import ImgFourteen from '../../../img/details/detailten/Img14.jpeg';
import ImgFiveteen from '../../../img/details/detailten/Img15.jpeg';
import ImgSixteen from '../../../img/details/detailten/Img16.jpeg';
import ImgEightteen from '../../../img/details/detailten/Img17.jpeg';





import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >
                     <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%" />  
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">ELEMATE</text>
                <text>
                    <label>THE BRAND</label>
                    Elemate is born from the vision of offering the market a solution based on the power of oxygen to have an impact on people's health and well-being, creating a line of high-end skincare products, providing innovation in skin care, and developing a unique, sophisticated, and contemporary brand.
                </text>
                <text>
                    <label>THE CHALLENGE</label>
                    The world of luxury skincare brands is highly competitive, and consumers have high expectations. Our challenge was to create an appealing brand for the luxury skincare market that reflects luxury, purity, and simplicity, akin to what oxygen conveys, while establishing a distinctive visual language. 					        
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  



        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;