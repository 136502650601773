import './index.scss'

//import HeaderContainer from "../header/";
import ReactPlayer from 'react-player'

import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detaileleven/1.mp4';
import ImgTwo from '../../../img/details/detaileleven/2.jpg';
import ImgThree from '../../../img/details/detaileleven/3.jpg';
import ImgFour from '../../../img/details/detaileleven/4.jpg';
import ImgFive from '../../../img/details/detaileleven/5.jpg';
import ImgSix from '../../../img/details/detaileleven/6.jpg';
import Brandin from '../../../img/details/detaileleven/7.jpg';
 
import ImgEight from '../../../img/details/detaileleven/8.jpg';
import ImgNine from '../../../img/details/detaileleven/9.jpg';
import ImgTen from '../../../img/details/detailten/Img10.jpeg';
import ImgEleven from '../../../img/details/detailten/Img11.jpeg';


 
import ImgTwelve from '../../../img/details/detailten/Img12.jpeg';
import ImgTherteen from '../../../img/details/detailten/Img13.jpeg';
import ImgFourteen from '../../../img/details/detailten/Img14.jpeg';
import ImgFiveteen from '../../../img/details/detailten/Img15.jpeg';
import ImgSixteen from '../../../img/details/detailten/Img16.jpeg';
import ImgEightteen from '../../../img/details/detailten/Img17.jpeg';





import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >
                    
         
                    <div className="Video">                
                            <ReactPlayer
                            url="https://tbestudio.com/videos/tbepage/NN1.mp4"
                            className='react-player'
                            playing
                            width='100%'
                            min-height='100%'
                            controls= 'true'

                            />                
                    </div>   
           
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">NOIR NICHE</text>
                <text>
                    <label>THE BRAND</label>
                        We are proud to be part of a project as unique and exciting as Noir Niche, a niche fragrance retail brand that is bringing the concept of niche fragrances to Latin America with a focus on introducing lesser- known perfume houses from around the world.
                        <br />
                        One of the key elements that sets Noir Niche apart is its commitment to storytelling and experiences. The world of perfume is not just about the aromas but also about the history, culture, and emotions that are part of it. That's why we've designed this brand to offer more than just a product; it's an entire one-of-a-kind experience. 
                </text>
                <text>
                    <label>THE CHALLENGE</label>
                        At the heart of this project was the task of creating a logo and brand that could effectively capture the essence of the diverse and complex world of Niche fragrances. We recognized the need for our design to reflect the multifaceted nature of this industry and its unique qualities, which set it apart from mass-produced perfumes.
						<br />
                        However, the true challenge lay in visually conveying the fleeting and intangible nature of fragrance itself. Our goal was to evoke the powerful emotions and sensations that these aromas can create in a way that would resonate with our audience.
                        <br />                  
                        To achieve this, we had to draw on our creativity, innovation, and delve into the art of perfume-making, resulting in a brand that captures the essence of the Niche perfume experience. 
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%" />  
                </div>
            </div>  




{/** 

            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgTen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgEleven} alt="React Logo"  width="100%" />  
                </div>
            </div>  








            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwelve} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTherteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgFourteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgFiveteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgSixteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

*/}

            {/*
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgEightteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgNineteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwenty} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyOne} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyThree} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyFour} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyFive} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentySix} alt="React Logo"  width="100%" />  
                </div>
            </div>  
    */}



        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;