import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailsix/Laila-01.jpg';
import ImgTwo from '../../../img/details/detailsix/Laila-02.jpg';
import ImgThree from '../../../img/details/detailsix/Laila-03.jpg';
import ImgFour from '../../../img/details/detailsix/Laila-04.jpg';
import ImgFive from '../../../img/details/detailsix/Laila-05.jpg';
import ImgSix from '../../../img/details/detailsix/Laila-06.jpg';
import Brandin from '../../../img/details/detailsix/Laila-07.jpg';
 
import ImgEight from '../../../img/details/detailsix/Laila-08.jpg';
import ImgNine from '../../../img/details/detailsix/Laila-09.jpg';
import ImgTen from '../../../img/details/detailsix/Laila-10.jpg';
import ImgEleven from '../../../img/details/detailsix/Laila-01.jpg';


import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >
                     <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%" />  
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">Laila</text>
                <text>
                    <label>THE BRAND</label>
                    Laila Comfort, es una marca dedicada al confort del hogar, 
                    con un enfoque en productos de calidad con un diseño atemporal. 
                    Sabemos que Laila Comfort será proximamente la marca referente 
                    en productos del descanso y el hogar.
                </text>
                <text>
                    <label>THE MISSION</label>
                    Llevar Laila Smiches de ser un producto, a ser Laila Comfort, 
                    una marca memorable con un logotipo amigable y accesible, 
                    conservando su parte onírica.
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgTen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgEleven} alt="React Logo"  width="100%" />  
                </div>
            </div>  


        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;