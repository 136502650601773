import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';






import ImgOne from '../../../img/details/detailthirteen/PORTADA.jpg';
import ImgTwo from '../../../img/details/detailthirteen/2.jpg';
import ImgThree from '../../../img/details/detailthirteen/3.jpg';
import ImgFour from '../../../img/details/detailthirteen/4.jpg';
import ImgFive from '../../../img/details/detailthirteen/5.jpg';
import ImgSix from '../../../img/details/detailthirteen/6.jpg';
import Brandin from '../../../img/details/detailthirteen/7.jpg';
 
import ImgEight from '../../../img/details/detailthirteen/8.jpg';
import ImgNine from '../../../img/details/detailthirteen/9.jpg';





import ImgTen from '../../../img/details/detailten/Img10.jpeg';
import ImgEleven from '../../../img/details/detailten/Img11.jpeg';


 
import ImgTwelve from '../../../img/details/detailten/Img12.jpeg';
import ImgTherteen from '../../../img/details/detailten/Img13.jpeg';
import ImgFourteen from '../../../img/details/detailten/Img14.jpeg';
import ImgFiveteen from '../../../img/details/detailten/Img15.jpeg';
import ImgSixteen from '../../../img/details/detailten/Img16.jpeg';
import ImgEightteen from '../../../img/details/detailten/Img17.jpeg';





import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >
                     <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%" />  
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">COOKIECAD</text>
                <text>
                    <label>THE BRAND</label>
                        Cookiecad is an innovative brand with a primary focus on providing premium filaments for 3D printers in a wide array of colors and finishes, including unique glitter options and gradients. With a commitment to quality, service and community, Cookiecad caters to both hobbyists and 3D printing professionals, looking for exceptional results.
                        <br />
                        In addition to their filament offerings, Cookiecad offers a personalized service: 3D printing of custom cookie cutters.
                        <br />
                        Cookiecad is growing, with online sales as well as a physical presence in MicroCenter stores. As a brand, they remain steadfast in their commitment to fueling creativity and pushing the boundaries of what's possible in the realm of 3D printing.                                                     
                </text>
                <text>
                    <label>THE CHALLENGE</label>              
                        With the Cookiecad rebranding we looked to establish an emotional connection with users, creating a sense of affinity with the brand's values and beliefs in excellence and innovative expression.
						<br/>
                        We wanted to move beyond the functional aspects of 3D printing and the technical specifications of the filaments and focus on the emotional side of creative endeavors and the
                        joy of artistic exploration, so as to foster a deeper connection with the Cookiecad community.
                        <br/>                   
                        By striking the right balance between creativity and quality in the brand's messaging with a consistent and authentic voice that resonates with users, we seek to build a loyal and engaged community of makers.                                                                     
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%" />  
                </div>
            </div>  



        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;