import { useState, useEffect , useRef, Fragment} from "react"
import './index.scss'







import Imagen1 from '../../img/pagefive/Ari/Ari_001.png'
import Imagen12 from '../../img/pagefive/Ari/Ari_002.png'
import Imagen2 from '../../img/pagefive/Bianca/Bianca_001.png'
import Imagen22 from '../../img/pagefive/Bianca/Bianca_002.png'
import Imagen3 from '../../img/pagefive/Brenda/Brenda1.png'
import Imagen32 from '../../img/pagefive/Brenda/Brenda2.png'
import Imagen4 from '../../img/pagefive/Carlos/Carlos_1.png'
import Imagen42 from '../../img/pagefive/Carlos/Carlos_002.png'
import Imagen5 from '../../img/pagefive/CarlosC/CarlosC1.png'
import Imagen52 from '../../img/pagefive/CarlosC/CarlosC2.png'
import Imagen6 from '../../img/pagefive/Jaime/Jaime_001.png'
import Imagen62 from '../../img/pagefive/Jaime/Jaime_002.png'
import Imagen7 from '../../img/pagefive/Rafa/Rafa_001.png'
import Imagen72 from '../../img/pagefive/Rafa/Rafa_001.png'
import Imagen8 from '../../img/pagefive/Vladimir/Vladimir1.png'
import Imagen82 from '../../img/pagefive/Vladimir/Vladimir2.png'




const CarruselTeam = () => {







  return(

  <div className="carruselContainerTeam">
  
  
    <div className="slideTeamCarrusel">
     
     
     
     
     
     
      <div className="slider uno inicio">
        <div className="containerPhoto">
          <div className="slideFoto">
            <div className="foto"></div>
            <div className="foto2"></div>
          </div>
       
          <div className="texto">
              <div className="textNameTeam">
                <text className="Name">ARI <text>WEINREICH</text></text>
                <text className="position">Director</text>
              </div>   
          </div>
        </div>
      </div> 
      <div className="slider dos">
        <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
              <div className="textNameTeam">
                <text className="Name">CARLOS <text>MANZO</text></text>
                    <text className="position">Creative Director</text>                  
              </div>   
          </div>
        </div>
      </div> 
      <div className="slider tres">
        <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
              <div className="textNameTeam">
                <text className="Name">JAIME <text>MARTINEZ</text></text>
                    <text className="position">Sr. Graphic Designer</text>                    
              </div>  
          </div>
        </div>
      </div> 
      <div className="slider cuatro">
        <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
          <div className="textNameTeam">
                <text className="Name">BIANCA <text>GONZALEZ</text></text>
                    <text className="position">Jr. Graphic Designer</text>
                
                </div>   
          </div>
        </div>
      </div> 
      <div className="slider cinco">
        <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
          <div className="textNameTeam">
                <text className="Name">RAFAEL <text>SALDAÑA</text></text>
                    <text className="position">Sr. 3D Graphic Designer</text>
                  
                </div>   
          </div>
        </div>
      </div> 
      <div className="slider seis">
      <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
          <div className="textNameTeam">
                <text className="Name">BRENDA <text>VACA</text></text>
                    <text className="position">Jr. Graphic Designer</text>
                    
                </div>   

          </div>
        </div>
      </div> 
      <div className="slider siete">
      <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
          <div className="textNameTeam">
                <text className="Name">VLADIMIR <text>ORTEGA</text></text>
                    <text className="position">Lead Developer</text>
                </div>   
          </div>
        </div>
      </div> 
      <div className="slider ocho">
      <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
            
          <div className="textNameTeam">
            
          <text className="Name">CARLOS <text>CADENA</text></text>                    
                    <text className="position">Sr. Graphic Designer</text>                 
             </div>   

          </div>
        </div>
      </div> 























      <div className="slider nueve">
        <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
          <div className="textNameTeam">
                <text className="Name">ELI <text>VEGA</text></text>
                    <text className="position">Project Manager</text>                     
                </div>   
          </div>
        </div>
      </div> 



      <div className="slider diez">
        <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
          <div className="textNameTeam">
            
            <text className="Name">COCO <text>JURADO</text></text>
                <text className="position">Sr. Graphic Designer</text>                     
            </div>   
          </div>
        </div>
      </div> 























      <div className="sliderclon uno">
      <div className="containerPhoto">
          <div className="slideFoto">
            <div className="foto"></div>
            <div className="foto2"></div>
          </div>
          <div className="texto">
              <div className="textNameTeam">
                <text className="Name">ARI <text>WEINREICH</text></text>
                <text className="position">Director</text>
              </div>   
          </div>
        </div>
      </div> 
      <div className="sliderclon dos">
      <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
              <div className="textNameTeam">
                <text className="Name">CARLOS <text>MANZO</text></text>
                    <text className="position">Creative Director</text>                  
              </div>   
          </div>
        </div>
      </div> 
      <div className="sliderclon tres">
      <div className="containerPhoto">
          <div className="foto"></div>
          <div className="texto">
              <div className="textNameTeam">
                <text className="Name">JAIME <text>MARTINEZ</text></text>
                    <text className="position">Sr. Graphic Designer</text>                    
              </div>  
          </div>
        </div>
      </div> 


    </div>


  </div>

  )  
}
  


export default CarruselTeam