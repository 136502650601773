import * as React from "react"

const IconWhats = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33.56}
    height={33.532}

  >
    <g data-name="Grupo 471">
      <path
        data-name="Trazado 238"
        d="M17.075 30.077a13.861 13.861 0 0 1-7.611-2.275l-5.307 1.685 1.713-5.112a13.584 13.584 0 0 1-2.639-8.06 10.405 10.405 0 0 1 .084-1.32 13.852 13.852 0 0 1 27.578.253c.028.365.028.73.028 1.067a13.82 13.82 0 0 1-13.845 13.761Zm7.554-10.447c-.421-.2-2.387-1.179-2.752-1.32-.394-.112-.646-.2-.927.225a19.621 19.621 0 0 1-1.265 1.544c-.253.281-.477.309-.87.112a10.558 10.558 0 0 1-3.257-1.994 11.922 11.922 0 0 1-2.248-2.752c-.225-.422-.028-.618.169-.843a5.154 5.154 0 0 0 .617-.7.715.715 0 0 0 .141-.169 3.94 3.94 0 0 0 .253-.477.688.688 0 0 0-.027-.7c-.086-.2-.9-2.191-1.237-2.977-.336-.814-.673-.674-.9-.674-.252 0-.505-.029-.786-.029a1.381 1.381 0 0 0-1.068.506 4.477 4.477 0 0 0-1.207 4.69 8.807 8.807 0 0 0 1.432 2.78c.2.281 2.78 4.437 6.88 6.038 4.129 1.6 4.129 1.067 4.859 1.011a4.012 4.012 0 0 0 2.725-1.91 3.2 3.2 0 0 0 .224-1.91c-.085-.14-.365-.253-.759-.449Zm8.9-3.707a16.459 16.459 0 0 0-32.887-.309 5.868 5.868 0 0 0-.028.7 16.343 16.343 0 0 0 2.36 8.453l-2.977 8.762 9.127-2.893a16.515 16.515 0 0 0 24.433-14.323c0-.14-.027-.253-.027-.393Z"
        fill={props.color}
        fillRule="evenodd"
      />
    </g>
  </svg>
)

export default IconWhats
