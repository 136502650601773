import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailfour/glo-pret-00.jpg';
import ImgTwo from '../../../img/details/detailfour/glo-pret-01.jpg';
import ImgThree from '../../../img/details/detailfour/glo-pret-02.jpg';
import ImgFour from '../../../img/details/detailfour/glo-pret-03.jpg';
import ImgFive from '../../../img/details/detailfour/glo-pret-04.jpg';
import ImgSix from '../../../img/details/detailfour/glo-pret-05.jpg';
import Brandin from '../../../img/details/detailfour/glo-pret-06.jpg';
 
import ImgEight from '../../../img/details/detailfour/glo-pret-07.jpg';
import ImgNine from '../../../img/details/detailfour/glo-pret-08.jpg';
import ImgTen from '../../../img/details/detailfour/glo-pret-09.jpg';
import ImgEleven from '../../../img/details/detailfour/glo-pret-10.jpg';


import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectFour = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectFour");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailFour" id='ProjectFour'>
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailFour">
                <div className="ContentProjectsViewImgageLeft" 
               
                >
                    <img loading="lazy" src={ImgOne} alt="React Logo" width="100%"  />  
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">Glo pret</text>
                <text>
                    <label>THE BRAND</label>
                    Glọ Prêt is a brand with the goal of bringing a world class beauty and 
                    spa experience to the Rockland community while respecting local customs and traditions.
                </text>
                <text>
                    <label>THE MISSION</label>
                    Give Glọ Prêt an identity that transmits luxury and exclusivity.
                    The challenge was to create something compelling yet modest that speaks to customers
                    from all walks of life allowing the brand to work both as a local beauty and wellness center.
                    We chose to do this thru an icon that is both memorable and powerful yet at the 
                    same time soft and airy, while maintaining the earth tones reflected throughout the interior design. 
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailFour">                
                <div className="ContentProjectsViewImgageRight"
                  
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo" width="100%"  />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailFour">
                <div className="ContentProjectsViewImgageLeft"
               
                >             
                <img loading="lazy" src={ImgThree} alt="React Logo" width="100%"  />  
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailFour">
               
                <div className="ContentProjectsViewImgageRight"
          
                >
                    <img loading="lazy" src={ImgFour} alt="React Logo" width="100%"  />  
                </div>
            </div>    



            <div className="ContentProjectsViewImgageTwoDetailFour">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo" width="100%"  />  
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailFour">
                <div className="ContentProjectsViewImgageLeft"
                
                >                               
                 <img loading="lazy" src={ImgFive} alt="React Logo" width="100%"  />  
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailFour">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgSix} alt="React Logo" width="100%"  />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailFour">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                      <img loading="lazy" src={ImgEight} alt="React Logo" width="100%"  />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailFour">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                      <img loading="lazy" src={ImgNine} alt="React Logo" width="100%"  />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailFour">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgTen} alt="React Logo" width="100%"  />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailFour">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                    <img loading="lazy" src={ImgEleven} alt="React Logo" width="100%"  />  
                </div>
            </div>  


        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectFour;