import React, {useState} from "react";
//import '../index.scss'


import Projects from '../../projects'

import HeaderContainer from "../header";
const HeaderNavProjects = (props) =>{
    if(props.name=='home'){
        setTimeout(function(){
            let element = document.getElementById("projectsHome");
            element.scrollIntoView({block: "start", behavior: "smooth"})
        }, 400);
    }
    return(
        <div id='projectsHome'>
            <HeaderContainer name='normalWhite'></HeaderContainer>
           
            <div className="containerProjects">
                <Projects name={props.name} /> 
            </div>

        </div>  
    )
}
export default HeaderNavProjects
