import React from "react";

import ContentProjectsTotal from '../views/projects';
import PageSeven from "../views/pagesevenblack";
import './index.scss'
const Projects = (props) =>{
    return(
        <>
                <ContentProjectsTotal name={props.name} />
                <PageSeven></PageSeven>
        </>
    )
}
export default Projects;