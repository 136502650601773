import react from "react";

import './index.scss'
import './ipad.scss'
import './mobile.scss'

import Create from '../../img/pagefive/Create.png'


import CarruselTeam from "../carruselteam";
import ContainerTransicion from "../transicion";

const NewCarrusel = () =>{
return(
    <div className="containerContentPageFive" id="PageFive">
        <div className="containerContentPageFiveContentPrincipal">
            <div className="containerContentPageFiveContentPrincipalHeader">
                <text className="textOne">
                    the
                </text>
                <text className="textTwo">
                    TEAM
                </text>              
            </div>
           
           





            <div className="containerContentPageFiveContentPrincipalCentral">
                <CarruselTeam></CarruselTeam>
            </div>













            <div className="containerContentPageFiveContentPrincipalFooter">
                <text className="textOne">
                    Extraordinary People
                </text>
                <text className="textTwo">                  
                   
                    <img src={Create} className='imagenCreate'  id='imagenCreate' style={{marginLeft:"1vw", marginBottom:"1vh"}}></img>
                </text>
            </div>
        </div>
    </div>
)
}

export default NewCarrusel;