import { useState, useEffect } from "react";
import './index.scss'
//import './ipad.scss'
//import './mobile.scss'

import BrandingExpertsLogo from '../../img/pageone/TheBranding.jsx';
import Branding from '../../img/pageone/TheBranding.svg';
import Flecha from "../../img/flechanegra/Trazado.svg";
import Elipse from '../../img/flechanegra/Elipse.svg'
import { useRef } from "react";
import Nuff from "../../img/pageone/Nuff";
import Animacion from "../animacionhome";

import ReactPlayer from 'react-player'












const PageOne = () =>{

    const [opacidad, setOpacidad] = useState(1);

    useEffect(() => {
      const handleScroll = () => {
        const posicionDesplazamiento = window.pageYOffset;
        const opacidadCalculada = 1 - posicionDesplazamiento / 280; // Ajusta según necesites
        setOpacidad(opacidadCalculada);
      }; 
      
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  







    return(
        <div className="containerContentPageOne" id="paginaHome" >
            <div className="contentPageOne">
                
            <ReactPlayer
                    url='https://bpmagency.mx/utils/Animacionconstruccion.mp4'
                    className='react-player'                                   
                    controls= {false}
                    loop={true}
                    muted={true}
                    playing={true}
                    style={{ opacity: opacidad }}
                    />                

            <div className='react-playerMobil' />                



                {/*
                <div className='oneContentPageOne'>                               
                        <Animacion></Animacion>
                        <div className="twoContentPageOne">
                            <Nuff width='50%'></Nuff>
                        </div>
                    
                </div>
                */}
                {/*
                <div className="threeContentPageOne">
                    <div className="slideHorizontal">
                        <div className="subContentOnePageOne">
                                            
                        </div>
                        <div className="subContentTwoPageOne">
                                             
                        </div>  
                    </div>       
                    <div className="Flecha">
                        <div  className="Imagen">
                            <img loading="lazy" src={Flecha} alt="React Logo" />
                        </div>
                                 
                    </div>                    
                </div>
                */}
            </div>
            
         {
            /*
            
            <div className="Texto1" id="Texto1" style={{
                display:"none"
            }}>
                <text>
                    You know your
                    business has what it takes ...  
                </text>                            
            </div>

            <div className="Texto2" id="Texto2" style={{
                display:"none"
            }}>
                <text>
                    ...We know how 
                    to get you there  
                </text>
                 
            </div>
             */
         }
        </div>

           

    )
}

export default PageOne;


  
       