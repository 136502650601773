import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailten/Img1.jpeg';
import ImgTwo from '../../../img/details/detailten/Img2.jpeg';
import ImgThree from '../../../img/details/detailten/Img3.jpg';
import ImgFour from '../../../img/details/detailten/Img4.gif';
import ImgFive from '../../../img/details/detailten/Img5.jpeg';
import ImgSix from '../../../img/details/detailten/Img6.jpeg';
import Brandin from '../../../img/details/detailten/Img7.jpeg';
 
import ImgEight from '../../../img/details/detailten/Img8.jpeg';
import ImgNine from '../../../img/details/detailten/Img9.jpeg';
import ImgTen from '../../../img/details/detailten/Img10.jpeg';
import ImgEleven from '../../../img/details/detailten/Img11.jpeg';


 
import ImgTwelve from '../../../img/details/detailten/Img12.jpeg';
import ImgTherteen from '../../../img/details/detailten/Img13.jpeg';
import ImgFourteen from '../../../img/details/detailten/Img14.jpeg';
import ImgFiveteen from '../../../img/details/detailten/Img15.jpeg';
import ImgSixteen from '../../../img/details/detailten/Img16.jpeg';
import ImgEightteen from '../../../img/details/detailten/Img17.jpeg';





import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >
                     <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%" />  
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">ZOEY</text>
                <text>
                    <label>THE BRAND</label>
                    Zoey is a European brand focused on providing high-end wigs for the discerning woman. Their products consist of natural hair with contemporary styles and maximum quality.
                    <br></br>
                    We aim to convey concepts such as elegance, luxury, and comfort by applying strokes and images that exude beauty and status with a European flair. Part of the challenge was presenting the brand as elegant and luxurious while maintaining a solid and memorable design.
                </text>
                <text>
                    <label>THE MISSION</label>
                    We decided to employ European, elegant, and organic strokes in their logo to evoke the blend of luxury and the movement of hair. The subtlety of these strokes allows for the appreciation of a modern, elegant, and dynamic image.
                    <br></br>
                    The applied colors express luxury and elegance with a feminine touch, paying special attention to the applications to achieve the impact the brand requires.
                   
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgTen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgEleven} alt="React Logo"  width="100%" />  
                </div>
            </div>  








            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwelve} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTherteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgFourteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgFiveteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgSixteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  



            {/*
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgEightteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgNineteen} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwenty} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyOne} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyThree} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyFour} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentyFive} alt="React Logo"  width="100%" />  
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailSix">              
                <div className="ContentProjectsViewImgageRight">
                    <img loading="lazy" src={ImgTwentySix} alt="React Logo"  width="100%" />  
                </div>
            </div>  
    */}



        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;