import * as React from "react"

const IconInsta = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.ancho} height={props.alto}>
    <g data-name="Grupo 812" transform="translate(-1759 -40)" >
      <circle
        data-name="Elipse 35"
        cx={16.5}
        cy={16.5}
        r={16.5}
        transform="translate(1759 40)"
        fill={props.color}
      />
      <g data-name="Grupo 473">
        <g data-name="Grupo 147">
          <g data-name="Grupo 144">
            <path
              data-name="Trazado 234"
              d="M1779.613 65.741h-7.763a5.135 5.135 0 0 1-5.129-5.129v-7.763a5.135 5.135 0 0 1 5.129-5.127h7.763a5.135 5.135 0 0 1 5.129 5.129v7.763a5.135 5.135 0 0 1-5.129 5.127Zm-7.763-16.476a3.588 3.588 0 0 0-3.584 3.584v7.763a3.589 3.589 0 0 0 3.584 3.584h7.763a3.588 3.588 0 0 0 3.584-3.584v-7.763a3.588 3.588 0 0 0-3.584-3.584Z"
              fill={props.relleno}
            />
          </g>
          <g data-name="Grupo 145">
            <path
              data-name="Trazado 235"
              d="M1775.731 61.345a4.614 4.614 0 1 1 4.614-4.614 4.619 4.619 0 0 1-4.614 4.614Zm0-7.684a3.07 3.07 0 1 0 3.07 3.069 3.073 3.073 0 0 0-3.07-3.068Z"
              fill={props.relleno}
            />
          </g>
          <g data-name="Grupo 146">
            <path
              data-name="Trazado 236"
              d="M1781.653 51.853a1.109 1.109 0 1 1-1.109-1.109 1.109 1.109 0 0 1 1.109 1.109Z"
              fill={props.relleno}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconInsta
