import React,{useState} from 'react';
import './index.scss'

import Lottie from 'react-lottie';
import animationData from './data.json';







const AnimacionDos = () => {

  let Altura
  let Ancho

    if(window.innerWidth>1200){
      Altura ='60vh';
      Ancho = '30vw';
    }else if(window.innerWidth>1000){     
      Altura ='60vh';
      Ancho = '30vw';
    }else if(window.innerWidth>800){     
      Altura ='50vh';
      Ancho = '30vw';
    }else if(window.innerWidth>850 && window.innerHeight<1200){     
      Altura ='50vh';
      Ancho = '30vw';
    }else if(window.innerWidth>700){     
      Altura ='50vh';
      Ancho = '30vw';
    }else{
      Altura ='40vh';
      Ancho = '25vw';
    }

    const [state, setState] = useState(false)

    setTimeout(function(){
     setState(true)
     }, 1500);
  
    return (
      <div className='containerAnimacionDosGeneral'>
        <div className='Espacio'></div>
        <div className='containerAnimacion'>
        
        <Lottie 
          options={
          {
              loop: true, 
              autoplay: state,        
              animationData: animationData,      
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              
          }}}

          height={Altura}
          width={Ancho}
        />
      </div>
      </div>
     
    );
}

export default AnimacionDos;