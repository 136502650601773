import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailtwo/RC-01.jpg';
import ImgTwo from '../../../img/details/detailtwo/RC-02.jpg';
import ImgThree from '../../../img/details/detailtwo/RC-03.jpg';
import ImgFour from '../../../img/details/detailtwo/RC-04.jpg';
import ImgFive from '../../../img/details/detailtwo/RC-05.jpg';
import ImgSix from '../../../img/details/detailtwo/RC-06.jpg';
import Brandin from '../../../img/details/detailtwo/RC-06.jpg';
 
import ImgEight from '../../../img/details/detailtwo/RC-01.jpg';
import ImgNine from '../../../img/details/detailtwo/RC-02.jpg';
import ImgTen from '../../../img/details/detailtwo/RC-03.jpg';
import ImgEleven from '../../../img/details/detailtwo/RC-04.jpg';


import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectTwo = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectTwo");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailTwo" id="ProjectTwo">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailTwo">
                <div className="ContentProjectsViewImgageLeft" 
            
                >
                      <img src={ImgOne} width='100%'></img>
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">Reserve cut</text>
                <text>
                    <label>BRAND</label>
                    We are more than a barbershop, we are a premium space dedicated 
                    exclusively to men to leave behind stress and everything that overwhelms you.
                    Reserve Cut is your time, we guarantee a luxurious, personalized and relaxing 
                    experience because we think that male beauty is a priority.... 
                    We have the best selected barbers with private cabins and the best 
                    treatments for self-care. We offer everything a man needs (a good hair 
                    cut and shave, good treatments and products accompanied by the best experience 
                    and service.

                </text>
                <text>
                    <label>THE MISSION</label>
                    Reserve Cut’s mission is to provide a premium experience of male self-care
                    with the best selected barbers and ultimate treatments to turn a routine into a ritual.
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailTwo">                
                <div className="ContentProjectsViewImgageRight"
         
                >
                      <img src={ImgTwo} width='100%'></img>
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailTwo">
                <div className="ContentProjectsViewImgageLeft"
        
                >             
                  <img src={ImgThree} width='100%'></img>
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailTwo">
               
                <div className="ContentProjectsViewImgageRight"
              
                >
                      <img src={ImgFour} width='100%'></img>
                </div>
            </div>    


           

            <div className="ContentProjectsViewImgageTwoDetailTwo">                
                <div className="ContentProjectsViewImgageRight"
                  
                >
                      <img src={Brandin} width='100%'></img>
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailTwo">
                <div className="ContentProjectsViewImgageLeft"
         
                >                    
                  <img src={ImgFive} width='100%'></img>           
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailTwo">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                      <img src={ImgSix} width='100%'></img>
                </div>
            </div>  


        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectTwo;