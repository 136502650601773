import React, {useRef, useState} from "react";
import '../index.scss'

import '../generalContainer.scss'
import './index.scss'

import HeaderContainer from "../header";

import PageOne from "../../views/pageone";
import PageTwo from "../../views/pagetwo";
import PageThree from '../../views/pagethree'
import PageFour from "../../views/pagefour";
//import PageFive from "../../views/pagefive";
import PageSix from "../../views/pagesix";
import PageSeven from "../../views/pageseven";


import NewCarrusel from "../../views/newcarrusel";
const HeaderNav = (props) =>{

    const[encabezado, setEncabezado] = useState("normal")
   

    window.addEventListener('scroll', (event) => {
        const AltoPantalla = window.innerHeight;

        // Filtro para Iniccio de Home
        if(window.scrollY >0){
            let porcentaje = ((100/AltoPantalla) * window.scrollY)/100;
            let Elemento = document.getElementById("filtroHome")
            Elemento.style.backgroundColor=  `rgba(290, 80, 15, ${1-(porcentaje)})`;
        }






        //Filtro para final de Home

        if(window.scrollY >(2.5*AltoPantalla)){


            if(window.innerWidth >700){
                let porcentaje = ((100/(AltoPantalla/4)) * (window.scrollY-(4.45*AltoPantalla)))/100;
                let Elemento = document.getElementById("filtroHome")
               // Elemento.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;                
            }



            /*


            if(window.innerWidth <700){
                let porcentaje = ((100/(AltoPantalla/5)) * (window.scrollY-(5.1*AltoPantalla)))/100;
                let Elemento = document.getElementById("PaginaFooter")
                let Elemento2 = document.getElementById("PageFive")
                
                Elemento.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                Elemento2.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;





                if(window.scrollY <(5.25*AltoPantalla)){
                    let Elemento = document.getElementById("PaginaFooter")
                    let Elemento2 = document.getElementById("PageFive")          
                    Elemento.style.backgroundColor=  'rgb(0, 0, 0)';
                    Elemento2.style.backgroundColor=  'rgb(0, 0, 0)';
                }
            }
            */
        }









        if(window.innerWidth <10){
           
            if(window.scrollY >(2.8*AltoPantalla)){
                let porcentaje = ((100/(AltoPantalla*1.2)) * (window.scrollY-(5.35*AltoPantalla)))/100;
                let Elemento = document.getElementById("PaginaFooter")
                let Elemento2 = document.getElementById("PageFive")
                
                Elemento.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                Elemento2.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
            }
            if(window.scrollY >(5.8*AltoPantalla)){
                let Elemento = document.getElementById("PaginaFooter")
                let Elemento2 = document.getElementById("PageFive")
                Elemento.style.backgroundColor=  'rgba(290, 80, 15, 0.8)';
            }
            if(window.scrollY <(5.2*AltoPantalla)){
                let Elemento = document.getElementById("PaginaFooter")             
                Elemento.style.backgroundColor=  'rgb(0, 0, 0)';
            }
        }
    });

  
return(
        <div className="absoluteServices" id="general" >             
             <HeaderContainer name='normal' />
             <div id="filtroHome">
            </div>
             <div className="containerHomePage" id='Home' >
                <PageOne ></PageOne>
                <PageTwo ></PageTwo>
                <div id="box">
                    <PageThree></PageThree>
                </div>                  
                <PageFour  ></PageFour>
                <NewCarrusel></NewCarrusel>
                <div className="FooterServicios" >
                    <PageSeven></PageSeven> 
                </div>                
            </div>              
          
        </div>  
    )
}
export default HeaderNav
