import * as React from "react"

const IconFace = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={39} height={39} >
    <g data-name="Grupo 475" transform="translate(-1471 -435)">
      <circle
        data-name="Elipse 36"
        cx={19.5}
        cy={19.5}
        r={19.5}
        transform="translate(1471 435)"
        fill={props.color}
      />
      <g data-name="Grupo 472">
        <path
          data-name="Trazado 237"
          d="M1491.896 449.713v-1.752a.923.923 0 0 1 .967-1.04h2.445v-3.741h-3.376a4.237 4.237 0 0 0-4.58 4.562v1.971h-2.171v4.36h2.19v10.893h4.361v-10.892h3.23l.146-1.715.255-2.646Z"
          fill="#fefefe"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
)

export default IconFace
