import React from "react";
import './index.scss'
import './ipad.scss'
import './mobile.scss'
import { Link } from "react-router-dom";

import Flecha from "../../img/flecha";
import Elipse from '../../img/pagethree/Elipse.svg';
const PageFour = () =>{
    

    return(
        <div className="containerContentPageFour">
            <div className="pageContentFour">
                <div className="pageContentFourHeader">
                    <div className="pageContentFourHeaderLeft">

                        <a href="/projects" style={{ textDecoration:"none", color:"#FFF" }}>
                            <img loading="lazy" src={Elipse} className='textwork' alt="React Logo" width="15vw" />                                               
                        </a>
                        <a href="/projects" style={{ marginLeft:"15px", color:"#FFF" }}>
                            <label >Our Work</label>         
                        </a>
                    </div>
                    <div className="pageContentFourHeaderRight">
                        We take pride in your success.
                    </div>
                </div>
                <div className="pageContentFourCentral">
                    <Link to="/Elemate" className="pageContentFourCentralContentOne">  
                        <div id="algo" >
                            <div className="pageContentFourCentralContentOneImage">

                            </div>
                        </div>                        
                        <div className="pageContentFourCentralContentOneFooter">
                            <div className="textOne">
                                <div className="Links">
                                    <text>Elemate</text> 
                                    <Flecha className="divFlecha" />   
                                </div>                                                                                         
                            </div>                            
                            <div className="textTwo">
                                    Branding
                                </div>
                        </div>
                    </Link>     
                    <Link to="/Amuze" className="pageContentFourCentralContentTwo">  
                        <div id="algo" >
                            <div className="pageContentFourCentralContentTwoImage">
                            </div>
                        </div>
                        
                        <div className="pageContentFourCentralContentTwoFooter">
                            <div className="textOne">          
                                <div className="Links">
                                    <text>Amuze</text> 
                                    <Flecha className="divFlecha" />
                                </div>                       
                            </div>
                            <div className="textTwo">
                                Re-Branding
                            </div>
                        </div>
                    </Link>      
                    <Link to="/Bigcity" className="pageContentFourCentralContentThree">
                        <div id="algo" >
                            <div className="pageContentFourCentralContentThreeImage"></div>
                        </div>  
                        
                        <div className="pageContentFourCentralContentThreeFooter">
                            <div className="textOne">  
                                <div className="Links">
                                    <text>Big City</text> 
                                    <Flecha className="divFlecha" />                 
                                </div>
                            </div>
                            <div className="textTwo">
                                Re-Branding
                            </div>
                        </div>
                    </Link>       
                </div>
                <div className="pageContentFourFooter">
                    <div className="pageContentFourFooterLeft"> 

                    </div>
                    <div className="pageContentFourFooterRight">
                        <Link to="/projects" className="Links">  
                            <text>More Projects</text> 
                            <Flecha className="divFlecha" />
                        </Link>                                                                                                                    
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PageFour;