import * as React from "react"

const TBE = (props) => (
  <svg id="Capa_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.38 22.31">
  <defs>
    <style>
    </style>
  </defs>
  <g id="Capa_1-2" data-name="Capa_1">
    <g>
      <g>
        <path class="cls-1" d="M10.12,13.01c0,3.53-2.46,5.89-6.14,5.89H0V7.14h3.98c3.68,0,6.14,2.34,6.14,5.87ZM8.9,13.03c0-2.88-1.92-4.82-4.87-4.82H1.19v9.62h2.84c2.94,0,4.87-1.92,4.87-4.8Z"/>
        <path class="cls-1" d="M19.18,15.42h-6.29c.17,1.54,1.39,2.61,3.04,2.61,1.12,0,1.79-.44,2.31-.85l.55.87c-.6.48-1.49,1-2.98,1-2.37,0-4.08-1.69-4.08-4s1.57-4,3.88-4c2.11,0,3.58,1.56,3.58,3.85,0,.3-.02.52-.02.52ZM12.93,14.45h5.13c-.08-1.46-1.1-2.38-2.41-2.38-1.44,0-2.49.95-2.73,2.38Z"/>
        <path class="cls-1" d="M20.51,18.13l.59-.95c.62.47,1.44.84,2.58.84,1.07,0,1.74-.4,1.74-1.15,0-.67-.4-1-1.84-1.37-1.69-.43-2.71-1.02-2.71-2.26,0-1.3,1.12-2.16,2.81-2.16,1.27,0,2.12.37,2.74.8l-.54.92c-.8-.48-1.37-.69-2.31-.69-.85,0-1.54.32-1.54,1.04,0,.62.52.89,1.96,1.25,1.97.52,2.59,1.14,2.59,2.42s-1.2,2.22-2.91,2.22c-1.3,0-2.26-.35-3.16-.92Z"/>
        <path class="cls-1" d="M28.27,8.7c0-.54.4-.89.92-.9.54,0,.94.37.94.9s-.4.9-.94.9-.92-.37-.92-.9ZM28.62,11.2h1.19v7.69h-1.2l.02-7.69Z"/>
        <path class="cls-1" d="M39.67,11.22v7.26c0,2.17-1.42,3.83-4.05,3.83-1.71,0-2.71-.49-3.4-.95l.54-.9c.59.37,1.47.82,2.79.82,1.86,0,2.93-1.15,2.93-2.66v-.99c-.6.82-1.57,1.42-2.91,1.42-2.09,0-3.73-1.71-3.73-4s1.61-4,3.7-4c1.49,0,2.53.7,3.06,1.54l.03-1.37h1.04ZM38.55,15.05c0-1.67-1.12-2.91-2.78-2.91-1.5,0-2.74,1.24-2.74,2.91s1.24,2.91,2.74,2.91c1.66,0,2.78-1.22,2.78-2.91Z"/>
        <path class="cls-1" d="M49.44,14.63v4.26h-1.19v-4.11c0-1.62-.95-2.66-2.37-2.64-1.52,0-2.34,1.14-2.46,2.19l-.02,4.57h-1.19v-7.69h.97l.05,1.44c.44-.87,1.44-1.59,2.84-1.59,2.01,0,3.36,1.42,3.36,3.58Z"/>
        <path class="cls-1" d="M58.79,15.42h-6.29c.17,1.54,1.39,2.61,3.04,2.61,1.12,0,1.79-.44,2.31-.85l.55.87c-.6.48-1.49,1-2.98,1-2.37,0-4.08-1.69-4.08-4s1.57-4,3.88-4c2.11,0,3.58,1.56,3.58,3.85,0,.3-.02.52-.02.52ZM52.53,14.45h5.13c-.08-1.46-1.1-2.38-2.41-2.38-1.44,0-2.49.95-2.73,2.38Z"/>
        <path class="cls-1" d="M68.09,6.96v11.94h-1.04l-.05-1.4c-.59.89-1.59,1.55-3.03,1.55-2.09,0-3.73-1.71-3.73-4s1.61-4,3.7-4c1.44,0,2.44.69,2.99,1.49v-5.58h1.15ZM66.95,15.05c0-1.67-1.12-2.91-2.78-2.91-1.51,0-2.74,1.24-2.74,2.91s1.24,2.91,2.74,2.91c1.66,0,2.78-1.22,2.78-2.91Z"/>
        <path class="cls-1" d="M82.15,11.2v7.69h-1.04l-.03-1.39c-.58.89-1.61,1.54-3.04,1.54-2.09,0-3.73-1.71-3.73-4s1.61-4,3.7-4c1.51,0,2.54.69,3.08,1.52l.03-1.37h1.04ZM81.02,15.05c0-1.67-1.12-2.91-2.78-2.91-1.5,0-2.74,1.24-2.74,2.91s1.24,2.91,2.74,2.91c1.66,0,2.78-1.22,2.78-2.91Z"/>
        <path class="cls-1" d="M91.92,14.63v4.26h-1.19v-4.11c0-1.62-.95-2.66-2.37-2.64-1.52,0-2.34,1.14-2.46,2.19l-.02,4.57h-1.19v-7.69h.97l.05,1.44c.44-.87,1.44-1.59,2.84-1.59,2.01,0,3.36,1.42,3.36,3.58Z"/>
        <path class="cls-1" d="M101.67,6.96v11.94h-1.04l-.05-1.4c-.58.89-1.59,1.55-3.03,1.55-2.09,0-3.73-1.71-3.73-4s1.61-4,3.7-4c1.44,0,2.44.69,2.99,1.49v-5.58h1.15ZM100.53,15.05c0-1.67-1.12-2.91-2.78-2.91-1.5,0-2.74,1.24-2.74,2.91s1.24,2.91,2.74,2.91c1.66,0,2.78-1.22,2.78-2.91Z"/>
        <path class="cls-1" d="M116.25,15.05c0,2.29-1.64,4-3.73,4-1.44,0-2.44-.67-3.03-1.55l-.05,1.4h-1.04V6.96h1.15v5.58c.55-.8,1.56-1.49,2.99-1.49,2.09,0,3.7,1.71,3.7,4ZM115.07,15.05c0-1.67-1.24-2.91-2.74-2.91-1.66,0-2.78,1.24-2.78,2.91s1.12,2.91,2.78,2.91c1.5,0,2.74-1.24,2.74-2.91Z"/>
        <path class="cls-1" d="M125.25,11.2v7.69h-1.04l-.03-1.32c-.45.8-1.41,1.47-2.69,1.47-1.99,0-3.33-1.42-3.33-3.58v-4.26h1.19v4.05c0,1.61.89,2.66,2.27,2.66,1.62-.02,2.43-1.32,2.44-2.43v-4.28h1.19Z"/>
        <path class="cls-1" d="M127.48,8.7c0-.54.4-.89.92-.9.54,0,.94.37.94.9s-.4.9-.94.9-.92-.37-.92-.9ZM127.83,11.2h1.19v7.69h-1.2l.02-7.69Z"/>
        <path class="cls-1" d="M131.63,6.96h1.19v11.94h-1.19V6.96Z"/>
        <path class="cls-1" d="M135.94,16.39v-4.21h-1.57v-.97h1.59v-2.64h1.15v2.64h3.23v.97h-3.23v4.21c0,1.12.6,1.61,1.72,1.61.87,0,1.35-.47,1.54-.58l.45.92c-.23.18-.92.72-2.19.72-1.56,0-2.69-.95-2.69-2.66Z"/>
        <path class="cls-1" d="M154.19,15.05c0,2.29-1.64,4-3.73,4-1.44,0-2.44-.67-3.03-1.55l-.05,1.4h-1.04V6.96h1.15v5.58c.55-.8,1.56-1.49,2.99-1.49,2.09,0,3.7,1.71,3.7,4ZM153,15.05c0-1.67-1.24-2.91-2.74-2.91-1.66,0-2.78,1.24-2.78,2.91s1.12,2.91,2.78,2.91c1.5,0,2.74-1.24,2.74-2.91Z"/>
        <path class="cls-1" d="M159.96,18.68l-1.47,3.48h-1.22l1.34-3.21-3.36-7.74h1.34l2.66,6.36,2.66-6.36h1.27l-3.21,7.48Z"/>
      </g>
      <g>
        <path class="cls-1" d="M214.33,6.6c-.17.2-.46.24-.67.08-.57-.43-.45-5.48-4.16-5.48-2.21,0-4.11,1.68-4.11,7.62v9.58c0,.44.36.79.79.79h16.4c.44,0,.79-.36.79-.79v-9.58c0-6.12-1.24-8.83-3.94-8.83-3.66,0-4.56,5.95-5.11,6.6"/>
        <path class="cls-1" d="M196.41,1.21h-7.4c-.44,0-.79.36-.79.79v16.4c0,.44.36.79.79.79h6.51c.46,0,.83-.4.79-.86l-.26-3.18c-.04-.42.12-.66.48-.76,2.15-.57,8.28-1.89,7.57-7.71-.42-3.44-2.82-5.48-7.69-5.48Z"/>
        <path class="cls-1" d="M182.82,7.89c-.51-.27-.52-.59-.21-.87.61-.54,2.08-1.56,1.89-3.14-.24-1.94-1.9-2.67-5.3-2.67h0s-7.38,0-7.38,0c-.44,0-.79.36-.79.79v16.4c0,.44.36.79.79.79h7.4c4.87,0,7.33-2.03,7.69-5.48s-1.88-4.68-4.09-5.83Z"/>
      </g>
    </g>
  </g>
</svg>
)

export default TBE
