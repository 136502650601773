import react from "react";


import './index.scss'

import ImgOne from '../../img/projects/Img1.jpg';

import ImgTwo from '../../img/projects/img2-1.png';
import ImgThree from '../../img/projects/Img3.png';
import ImgFour from '../../img/projects/Img4.png';
import ImgFive from '../../img/projects/Img5.png';
import ImgSix from '../../img/projects/Img6.jpg';

import ImgSeven from '../../img/projects/Img7.png';
import ImgEight from '../../img/projects/Img8.png';
import ImgNine from '../../img/projects/Img9.png';

import ImgTen from '../../img/details/detailten/img0Nuevo.png';






import ImgEleven from '../../img/details/detaileleven/2.jpg';
import ImgTwelve from '../../img/details/detailtwelve/2_PORTADA.gif';
import ImgThirteen from '../../img/details/detailthirteen/PORTADA.jpg';
import ImgFourteen from '../../img/details/detailfourteen/1_PORTADA.jpg';
import ImgFiveteen from '../../img/details/detailfiveteen/2_PORTADA.gif';

import ImgSixteen from '../../img/details/detailsixteen/1.gif';














import iconDirection from '../../img/projects/Elipse 39.svg';

import { Link } from "react-router-dom";


const ContentProjectsTotal = (props) => {

    if(props.name=='one'){
        setTimeout(function(){
            let element = document.getElementById("viewProjectOne");
            element.scrollIntoView({block: "start", behavior: "smooth"})
        }, 400);
    }
    if(props.name=='two'){
        setTimeout(function(){
            let element = document.getElementById("viewProjectTwo");
            element.scrollIntoView({block: "start", behavior: "smooth"})
        }, 400);
    }
    if(props.name=='three'){
        setTimeout(function(){
            let element = document.getElementById("viewProjectThree");
            element.scrollIntoView({block: "start", behavior: "smooth"})
        }, 400);
    }

    return(
    <div className="ContentProjects">
        


        <div  id='viewProjectTwo'>
            <Link to="/detailtwo" className="ContentProjectsViewImgageTwo">
                <div  className="ContentProjectsViewImgageLeft"> 
                    <text className="textOne">Reserve Cut</text>
                    <text className="textTwo">Branding Design</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text>  
                </div>
                <div className="ContentProjectsViewImgageRight">
                    <img src={ImgTwo} />
                </div>
            </Link>            
        </div>    




        <div  id="viewProjectThree">
            <Link to="/detailthree" className="ContentProjectsViewImgageOne">
                <div className="ContentProjectsViewImgageLeft">     
                    <img src={ImgThree} />        
                </div>
                <div className="ContentProjectsViewImgageRight">
                    <text className="textOne">Bluelander</text>
                    <text className="textTwo">Re-Branding</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text>
                </div>
            </Link>
        </div>       
        <div >
            <Link to="/detailfour" className="ContentProjectsViewImgageTwo">
                <div  className="ContentProjectsViewImgageLeft">
                    <text className="textOne">Glo Pret</text>
                    <text className="textTwo">Branding Design</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>
                <div className="ContentProjectsViewImgageRight">
                    <img src={ImgFour} />        
                </div>
            </Link>
        </div>    



        <div >
            <Link to="/detailfive" className="ContentProjectsViewImgageOne">
                <div className="ContentProjectsViewImgageLeft">                               
                    <img src={ImgFive} />      
                </div>
                <div className="ContentProjectsViewImgageRight"> 
                    <text className="textOne">FTK Beaty Group</text>
                    <text className="textTwo">Re-Branding</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>
            </Link>
        </div>       
        <div >
            <Link to="/detailsix" className="ContentProjectsViewImgageTwo">
                <div className="ContentProjectsViewImgageLeft">
                    <text className="textOne">Laila</text>
                    <text className="textTwo">Branding Design</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>                                   
                <div className="ContentProjectsViewImgageRight">
                      <img src={ImgSix} />     
                </div>                    
            </Link>
        </div>  










        <div >
            <Link to="/detailseven" className="ContentProjectsViewImgageOne">
                <div className="ContentProjectsViewImgageLeft">                               
                    <img src={ImgSeven} />      
                </div>
                <div className="ContentProjectsViewImgageRight"> 
                    <text className="textOne">Revelwear</text>
                    <text className="textTwo">Re-Branding</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>
            </Link>
        </div>       
        <div >
            <Link to="/detaileight" className="ContentProjectsViewImgageTwo">
                <div className="ContentProjectsViewImgageLeft">
                    <text className="textOne">Exoclinic</text>
                    <text className="textTwo">Branding Design</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>                                   
                <div className="ContentProjectsViewImgageRight">
                      <img src={ImgEight} />     
                </div>                    
            </Link>
        </div>  







        <div >
            <Link to="/detailnine" className="ContentProjectsViewImgageOne">
                <div className="ContentProjectsViewImgageLeft">                               
                    <img src={ImgNine} />      
                </div>
                <div className="ContentProjectsViewImgageRight"> 
                    <text className="textOne">M2C1</text>
                    <text className="textTwo">Re-Branding</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>
            </Link>
        </div>       


        <div >
            <Link to="/detailten" className="ContentProjectsViewImgageTwo">
                <div className="ContentProjectsViewImgageLeft">
                    <text className="textOne">ZOEY</text>
                    <text className="textTwo">Branding Design</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>                                   
                <div className="ContentProjectsViewImgageRight">
                      <img src={ImgTen} />     
                </div>                    
            </Link>
        </div>  


        <div  id='viewProjectOne'>
            <Link to="/detailone" className="ContentProjectsViewImgageOne">
                <div className="ContentProjectsViewImgageLeft">
                        <img src={ImgOne} />
                </div>
                <div to="/detailthree" className="ContentProjectsViewImgageRight">
                    <text className="textOne">Bart Street</text>
                    <text className="textTwo">Re-Branding</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>       
            </Link>
        </div>     


































        <div >
            <Link to="/Noirniche" className="ContentProjectsViewImgageTwo">
                <div className="ContentProjectsViewImgageLeft">
                    <text className="textOne">Noir Niche</text>
                    <text className="textTwo">Branding Design</text>                   
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>                                   
                <div className="ContentProjectsViewImgageRight">
                      <img src={ImgEleven} />     
                </div>                    
            </Link>
        </div>  
        <div  id=''>
            <Link to="/Cookiecad" className="ContentProjectsViewImgageOne">
                <div className="ContentProjectsViewImgageLeft">
                        <img src={ ImgThirteen } />
                </div>
                <div to="/detailthree" className="ContentProjectsViewImgageRight">
                    
                <text className="textOne">Cookiecad</text>
                    <text className="textTwo">Re-Branding</text>   
                         
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>       
            </Link>
        </div>     
        <div >
            <Link to="/Amuze" className="ContentProjectsViewImgageTwo">
                <div className="ContentProjectsViewImgageLeft">
                <text className="textOne">Amuze</text>
                    <text className="textTwo">Re-Branding</text>       
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>                                   
                <div className="ContentProjectsViewImgageRight">
                      <img src={ImgTwelve} />     
                </div>                    
            </Link>
        </div>  
        <div  id=''>
            <Link to="/Elemate" className="ContentProjectsViewImgageOne">
                <div className="ContentProjectsViewImgageLeft">
                        <img src={ImgFourteen} />
                </div>
                <div to="/detailthree" className="ContentProjectsViewImgageRight">
                    <text className="textOne">Elemate</text>
                    <text className="textTwo">Branding</text>                              
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>       
            </Link>
        </div>     
        <div >
            <Link to="/Bigcity" className="ContentProjectsViewImgageTwo">
                <div className="ContentProjectsViewImgageLeft">
                    <text className="textOne">Big City</text>
                    <text className="textTwo">Re-Branding</text>
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>                                   
                <div className="ContentProjectsViewImgageRight">
                      <img src={ImgFiveteen} />     
                </div>                    
            </Link>
        </div>  
        <div  id=''>
            <Link to="/Hivemind" className="ContentProjectsViewImgageOne">
                <div className="ContentProjectsViewImgageLeft">
                        <img src={ImgSixteen} />
                </div>
                <div to="/detailthree" className="ContentProjectsViewImgageRight">
                    <text className="textOne">Hivemind</text>
                    <text className="textTwo">Re-Branding</text>                              
                    <text className="textThree">
                        <div className="Flecha"></div>
                    </text> 
                </div>       
            </Link>
        </div>     



      


        <div className="ContentProjectsTitle">
            <div className="ContentProjectsLeft">
                <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                    <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                    Our Work
                </a>
            </div>
            <div className="ContentProjectsRight">
                We take pride in your success.
            </div>
        </div>


    </div>
    )
}

export default ContentProjectsTotal;