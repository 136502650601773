import * as React from "react"

const IconBe = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={39} height={39} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rect\xE1ngulo 135"
          fill={props.color}
          d="M0 0h24.184v15.316H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Grupo 677">
      <g data-name="Grupo 474" transform="translate(-1537 -435)">
        <circle
          data-name="Elipse 37"
          cx={19.5}
          cy={19.5}
          r={19.5}
          transform="translate(1537 435)"
          fill={props.back}
        />
      </g>
      <g data-name="Grupo 674">
        <g
          data-name="Grupo 673"
          clipPath="url(#a)"
          fill={props.fill}
          transform="translate(7.408 11.842)"
        >
          <path
            data-name="Trazado 714"
            d="M9.76 6.847a2.6 2.6 0 0 0 2.289-2.854C12.049 1.31 10.176 0 7.8 0H0v15h7.8s4.764.15 4.764-4.427c0 0 .208-3.724-2.809-3.724M3.439 2.666H7.8s1.061 0 1.061 1.56-.62 1.787-1.327 1.787h-4.1ZM7.6 12.333H3.439V8.325H7.8s1.581-.021 1.581 2.06c0 1.735-1.157 1.931-1.782 1.949"
          />
          <path
            data-name="Trazado 715"
            d="M18.923 3.816c-5.768 0-5.762 5.762-5.762 5.762a5.321 5.321 0 0 0 5.763 5.733s5.132.293 5.132-3.988h-2.639s.088 1.613-2.4 1.613a2.44 2.44 0 0 1-2.64-2.61h7.772s.85-6.511-5.22-6.511m2.346 4.509h-4.927a2.682 2.682 0 0 1 2.639-2.312 2.233 2.233 0 0 1 2.287 2.312"
          />
          <path
            data-name="Rect\xE1ngulo 134"
            d="M15.696.883h6.188V2.73h-6.188z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default IconBe
