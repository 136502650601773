import React, {useState} from "react";
import '../index.scss'
import '../generalContainer.scss'
import './index.scss'
import Contacts from "../../contacts";
import HeaderContainer from "../header/";

const HeaderNavContact= () =>{








    window.addEventListener('scroll', (event) => {
        const AltoPantalla = window.innerHeight;
        let Elemento = document.getElementById("PaginaFooter")
        
        let Elemento2= document.getElementById("containerFormContact")
        let Elemento3= document.getElementById("containerFormContactMovil")
     
        if(window.scrollY >(0*AltoPantalla)){
            if(window.innerWidth >700){
                let porcentaje = ((100/(AltoPantalla/4)) * (window.scrollY))/100;
                Elemento.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                Elemento2.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                Elemento3.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
            }



            if(window.innerWidth <700){
                let porcentaje = ((100/(AltoPantalla)) * (window.scrollY/1.8))/100;
                Elemento.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                Elemento2.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                Elemento3.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                
            }
            


            if(window.innerWidth <390){
                let porcentaje = ((100/(AltoPantalla/0.5)) * (window.scrollY/1.5))/100;
                Elemento.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                Elemento2.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                Elemento3.style.backgroundColor=  `rgba(290, 80, 15, ${(porcentaje)})`;
                
            }

        }
    });






    return(
        <div id='ContactPage' >
          
            < HeaderContainer name='normal'></HeaderContainer>
            
            <div className="containerProjects"  style={{backgroundColor:"transparent"}}>
                <Contacts></Contacts>
            </div>
        </div>  
    )
}
export default HeaderNavContact
