import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailfive/FTK-01.jpg';
import ImgTwo from '../../../img/details/detailfive/FTK-02.jpg';
import ImgThree from '../../../img/details/detailfive/FTK-03.jpg';
import ImgFour from '../../../img/details/detailfive/FTK-04.jpg';
import ImgFive from '../../../img/details/detailfive/FTK-05.jpg';
import ImgSix from '../../../img/details/detailfive/FTK-06.jpg';
import Brandin from '../../../img/details/detailfive/FTK-07.jpg';
 
import ImgEight from '../../../img/details/detailfive/FTK-08.jpg';
import ImgNine from '../../../img/details/detailfive/FTK-09.jpg';
import ImgTen from '../../../img/details/detailfive/FTK-01.jpg';
import ImgEleven from '../../../img/details/detailfive/FTK-02.jpg';


import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectFive = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectFive");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailFive" id='ProjectFive'>
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailFive">
                <div className="ContentProjectsViewImgageLeft" 
                  
                >
                    <img loading="lazy" src={ImgOne} alt="React Logo" width="100%" />  
                </div>
            </div>       

        


            <div className="ContentProjectsViewImgageTwoDetailFive">                
                <div className="ContentProjectsViewImgageRight"
             
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo" width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailFive">
                <div className="ContentProjectsViewImgageLeft"
                 
                >             
                  <img loading="lazy" src={ImgThree} alt="React Logo" width="100%" />  
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailFive">
               
                <div className="ContentProjectsViewImgageRight"
            
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo" width="100%" />  
                </div>
            </div>    


     
            <div className="ContentProjectsViewImgageTwoDetailFive">                
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={Brandin} alt="React Logo" width="100%" />  
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailFive">
                <div className="ContentProjectsViewImgageLeft"
          
                >                               
                 <img loading="lazy" src={ImgFive} alt="React Logo" width="100%" />  
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailFive">
              
                <div className="ContentProjectsViewImgageRight"
           
                >
                     <img loading="lazy" src={ImgSix} alt="React Logo" width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailFive">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo" width="100%" />
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailFive">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                      <img loading="lazy" src={ImgNine} alt="React Logo" width="100%" />
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailFive">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                      <img loading="lazy" src={ImgTen} alt="React Logo" width="100%" />
                </div>
            </div>  





            <div className="ContentProjectsViewImgageTwoDetailFive">
              
                <div className="ContentProjectsViewImgageRight">
                      <img loading="lazy" src={ImgEleven} alt="React Logo" width="100%" />
                </div>
            </div>  


        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectFive;