import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";

import ReactPlayer from 'react-player'

import iconDirection from '../../../img/projects/Elipse 39.svg';



import ImgOne from '../../../img/details/detaileleven/1.mp4';
import ImgTwo from '../../../img/details/detailtwelve/2_PORTADA.gif';
import ImgThree from '../../../img/details/detailtwelve/3.jpg';
import ImgFour from '../../../img/details/detailtwelve/4.gif';
import ImgFive from '../../../img/details/detailtwelve/5.jpg';
import ImgSix from '../../../img/details/detailtwelve/6.gif';
import Brandin from '../../../img/details/detailtwelve/7.gif';
 
import ImgEight from '../../../img/details/detailtwelve/8.jpg';
import ImgNine from '../../../img/details/detailtwelve/9.jpg';


import ImgTen from '../../../img/details/detailten/Img10.jpeg';
import ImgEleven from '../../../img/details/detailten/Img11.jpeg';


 
import ImgTwelve from '../../../img/details/detailten/Img12.jpeg';
import ImgTherteen from '../../../img/details/detailten/Img13.jpeg';
import ImgFourteen from '../../../img/details/detailten/Img14.jpeg';
import ImgFiveteen from '../../../img/details/detailten/Img15.jpeg';
import ImgSixteen from '../../../img/details/detailten/Img16.jpeg';
import ImgEightteen from '../../../img/details/detailten/Img17.jpeg';





import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >
                   
                   <div className="Video">                
                            <ReactPlayer
                            url="https://tbestudio.com/videos/tbepage/Amuze.mp4"
                            className='react-player'
                            playing
                            width='100%'
                            min-height='100%'
                            controls= 'true'

                            />                
                    </div>   




                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">AMUZE</text>
                <text>
                    <label>THE BRAND</label>
                    
                        Amuze stands out in the luxury fashion retail sector, offering its followers current season fashion products from the best-known design houses at discounted prices.
                        <br />
                        Additionally, Amuze brings pop-up stores to iconic cities such as New York City, Miami and Los Ángeles thereby connecting with customers. With an online interactive community, frequent giveaways and opportunities to interact in person at pop-up stores, Amuze is so much more than an online shopping platform, it’s a chance to be part of something bigger.
                        <br />              
                        Over the years thru it’s social media engagement and impressive pop-up stores, Amuze has established itself as a brand in it’s own right creating an alternative and successful business model in the luxury fashion sector with a community of fashion lovers worldwide. 

                </text>
                <text>
                    <label>THE CHALLENGE</label>
                        Redesign a brand that specializes in offering a variety of products from exclusive global luxury brands at discounted prices.
                        <br />
                        Our challenge was to bring the Amuze branding on par with established luxury brands such as Dior, Gucci, and Prada communicating trust in a market riddled with knock-off goods, while at the same time remaining true to the unique Amuze style. Given that Amuze is a brand that lives for the most part on social media creates another layer of difficulty as luxury is so often perceived thru physical aspects of quality, design and materials, the challenge was in finding the voice for Amuze that is refined yet bold and speaks to design, luxury and quality. 
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%" />  
                </div>
            </div>  



        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;