import react from "react";
import './index.scss'
import ReactPlayer from 'react-player'
//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detailthree/newImages/bluelander.jpg';
import ImgTwo from '../../../img/details/detailthree/newImages/bluelander2.jpg';
import ImgThree from '../../../img/details/detailthree/newImages/bluelander3.jpg';
import ImgFour from '../../../img/details/detailthree/newImages/bluelander4.jpg';
import ImgFive from '../../../img/details/detailthree/newImages/bluelander5.jpg';
import ImgSix from '../../../img/details/detailthree/newImages/bluelander6.jpg';
import Brandin from '../../../img/details/detailthree/newImages/bluelander7.jpg';
 
import ImgEight from '../../../img/details/detailthree/newImages/bluelander9.jpg';
import ImgNine from '../../../img/details/detailthree/newImages/bluelander10.jpg';
import ImgTen from '../../../img/details/detailthree/newImages/bluelander11.jpg';
import ImgEleven from '../../../img/details/detailthree/newImages/bluelander12.jpg';

import ImgTwelve from '../../../img/details/detailthree/newImages/bluelander13.jpg';
import ImgTheerteen from '../../../img/details/detailthree/newImages/bluelander14.jpg';
import ImgFourteen from '../../../img/details/detailthree/newImages/bluelander15.jpg';
import ImgFiveteen from '../../../img/details/detailthree/newImages/bluelander16.jpg';
import ImgSixteen from '../../../img/details/detailthree/newImages/bluelander17.jpg';
import ImgEightteen from '../../../img/details/detailthree/newImages/bluelander18.jpg';
import ImgNineteen from '../../../img/details/detailthree/newImages/bluelander19.jpg';
import ImgTwenty from '../../../img/details/detailthree/newImages/bluelander20.jpg';
import ImgTwentyOne from '../../../img/details/detailthree/newImages/bluelander21.jpg';


import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectTwo = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectThree");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailThree" id='ProjectThree'>
        <HeaderContainer name="white"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft">
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


         
            <div className="Video">                
                    <ReactPlayer
                    url='https://youtu.be/pXJW_fuWAGQ'
                    className='react-player'
                    playing
                    width='100%'
                    min-height='100%'
                    controls= 'true'

                    />                
            </div>   
           
{/**

            <div className="ContentProjectsViewImgageOneDetailThree">
                <div className="ContentProjectsViewImgageLeft"                    
                >
                     <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%"/>  
                </div>
            </div>       
 */}
            <div className="ContentProjectsViewText">                
                <text className="title">Bluelander</text>
                <text>
                    <label>THE BRAND</label>
                    Bluelander es una marca innovadora con una gran variedad de artículos 
                    y categorías que ofrece productos de calidad y diseño en Marketplaces digital.
                </text>
                <text>
                    <label>THE MISSION</label>
                    Crear un concepto con personalidad memorable que transmite la esencia de 
                    Bluelander, innovacion, diseño y calidad sobresaliendo de la competencia. 
                    Mantener la conexion al origen de la marca, con un diseño vanguardista que 
                    comunica la vision al futuro.
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailThree">                
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%"/>  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailThree">
                <div className="ContentProjectsViewImgageLeft"
                   
                >             
                 <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%"/>  
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailThree">
               
                <div className="ContentProjectsViewImgageRight"
              
                >
                     <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%"/>  
                </div>
            </div>    


            <div className="ContentProjectsViewText">                                
                <text>
                    <label>Logo</label>
                    We reference the infinity symbol in a subtle way. 
                    The blue color is maintained in a more vibrant tone, with a unique typeface approved for Bluelander.
                     We generate brand identity that speaks of design and innovation.
                </text>
                
            </div>    

            <div className="ContentProjectsViewImgageTwoDetailThree">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%"/>  
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailThree">
                <div className="ContentProjectsViewImgageLeft"
             
                >            
                 <img loading="lazy" src={ImgTen} alt="React Logo"  width="100%"/>                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailThree">
              
                <div className="ContentProjectsViewImgageRight"
              
                >
                     <img loading="lazy" src={ImgEleven} alt="React Logo"  width="100%"/>        
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailThree">
              
                <div className="ContentProjectsViewImgageRight"                 
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%"/> 
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailThree">
              
                <div className="ContentProjectsViewImgageRight"
      
                >
                      <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%"/> 
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailThree">
              
                <div className="ContentProjectsViewImgageRight"
             
                >
                    <img loading="lazy" src={ImgTen} alt="React Logo"  width="100%"/> 
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgEleven} alt="React Logo"  width="100%"/> 
                </div>
            </div>  
            




            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgTwelve} alt="React Logo"  width="100%"/> 
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgTheerteen} alt="React Logo"  width="100%"/> 
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgFourteen} alt="React Logo"  width="100%"/> 
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgFiveteen} alt="React Logo"  width="100%"/> 
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgSixteen} alt="React Logo"  width="100%"/> 
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgEightteen} alt="React Logo"  width="100%"/> 
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgNineteen} alt="React Logo"  width="100%"/> 
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgTwenty} alt="React Logo"  width="100%"/> 
                </div>
            </div>  
            <div className="ContentProjectsViewImgageTwoDetailThree">              
                <div className="ContentProjectsViewImgageRight"          
                >
                    <img loading="lazy" src={ImgTwentyOne} alt="React Logo"  width="100%"/> 
                </div>
            </div>  



        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectTwo;