import react from "react";
import './index.scss'

//import HeaderContainer from "../header/";


import iconDirection from '../../../img/projects/Elipse 39.svg';

import ImgOne from '../../../img/details/detaileight/EXO-1.jpg';
import ImgTwo from '../../../img/details/detaileight/EXO-2.gif';
import ImgThree from '../../../img/details/detaileight/EXO-3.jpg';
import ImgFour from '../../../img/details/detaileight/EXO-4.jpg';
import ImgFive from '../../../img/details/detaileight/EXO-5.jpg';
import ImgSix from '../../../img/details/detaileight/EXO-6.jpg';
import Brandin from '../../../img/details/detaileight/EXO-7.jpg';
 
import ImgEight from '../../../img/details/detaileight/EXO-9.jpg';
import ImgNine from '../../../img/details/detaileight/EXO-10.jpg';
import ImgTen from '../../../img/details/detaileight/EXO-11.jpg';
import ImgEleven from '../../../img/details/detaileight/EXO-12.jpg';



 
import ImgTwelve from '../../../img/details/detaileight/EXO-13.jpg';
import ImgTherteen from '../../../img/details/detaileight/EXO-14.jpg';
import ImgFourteen from '../../../img/details/detaileight/EXO-15.jpg';
import ImgFiveteen from '../../../img/details/detaileight/EXO-16.jpg';
import ImgSixteen from '../../../img/details/detaileight/EXO-17.jpg';
import ImgEightteen from '../../../img/details/detaileight/EXO-18.jpg';
import ImgNineteen from '../../../img/details/detaileight/EXO-19.jpg';
import ImgTwenty from '../../../img/details/detaileight/EXO-20.jpg';
import ImgTwentyOne from '../../../img/details/detaileight/EXO-12.jpg';




import HeaderContainer from '../../header/index'
import PageSeven from '../../../views/pagesevenblack'
const DetailProjectSix = () => {
    setTimeout(function(){
        let element = document.getElementById("ProjectSix");
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }, 400);
    return(
    <div className="containerDetailSix" id="ProjectSix">
        <HeaderContainer name="normalWhite"></HeaderContainer>


        <div className="ContentProjects">
            <div className="ContentProjectsTitle">
                <div className="ContentProjectsLeft" >
                    <a href="/projects" style={{ textDecoration:"none", color:"#000" }}>
                        <img loading="lazy" src={iconDirection} alt="React Logo"  />  
                        Our Work
                    </a>
                </div>
                <div className="ContentProjectsRight">
                    We take pride in your success
                </div>
            </div>


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft" 
                   
                >
                     <img loading="lazy" src={ImgOne} alt="React Logo"  width="100%" />  
                </div>
            </div>       

            <div className="ContentProjectsViewText">                
                <text className="title">Exoclinic</text>
                <text>
                    <label>THE BRAND</label>
                    A clinic dedicated to aesthetic medicine focused on beauty treatments for face and body. Located in one of the most glamorous avenues in the city, serving local and international clients.
                </text>
                <text>
                    <label>THE MISSION</label>
                    We created the name from Exo referring to the external and Clinic for the medical aspect thereby transmitting confidence and professionalism. We designed the graphic identity with powerful elements and strong colors to distinguish the brand from its competitors.
                </text>
            </div>    

         


            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
            
                >
                    <img loading="lazy" src={ImgTwo} alt="React Logo"  width="100%" />  
                </div>
            </div>    


            

            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
            
                >     
                <img loading="lazy" src={ImgThree} alt="React Logo"  width="100%" />          
                </div>
               
            </div>       

            <div className="ContentProjectsViewImgageTwoDetailSix">
               
                <div className="ContentProjectsViewImgageRight"
         
                >
                     <img loading="lazy" src={ImgFour} alt="React Logo"  width="100%" />      
                </div>
            </div>    


          

            <div className="ContentProjectsViewImgageTwoDetailSix">                
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={Brandin} alt="React Logo"  width="100%" />      
                </div>
            </div>    


            <div className="ContentProjectsViewImgageOneDetailSix">
                <div className="ContentProjectsViewImgageLeft"
                        
                >                
                <img loading="lazy" src={ImgFive} alt="React Logo"  width="100%" />                     
                </div>
               
            </div>       
            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgSix} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                  
                >
                     <img loading="lazy" src={ImgEight} alt="React Logo"  width="100%" />     
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                
                >
                     <img loading="lazy" src={ImgNine} alt="React Logo"  width="100%" />  
                </div>
            </div>  


            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                     <img loading="lazy" src={ImgTen} alt="React Logo"  width="100%" />  
                </div>
            </div>  

            <div className="ContentProjectsViewImgageTwoDetailSix">
              
                <div className="ContentProjectsViewImgageRight"
                 
                >
                    <img loading="lazy" src={ImgEleven} alt="React Logo"  width="100%" />  
                </div>
            </div>  







        <div className="ContentProjectsViewImgageTwoDetailSix">              
            <div className="ContentProjectsViewImgageRight">
                <img loading="lazy" src={ImgTwelve} alt="React Logo"  width="100%" />  
            </div>
        </div>  
        <div className="ContentProjectsViewImgageTwoDetailSix">              
            <div className="ContentProjectsViewImgageRight">
                <img loading="lazy" src={ImgTherteen} alt="React Logo"  width="100%" />  
            </div>
        </div>  
        <div className="ContentProjectsViewImgageTwoDetailSix">              
            <div className="ContentProjectsViewImgageRight">
                <img loading="lazy" src={ImgFourteen} alt="React Logo"  width="100%" />  
            </div>
        </div>  
        <div className="ContentProjectsViewImgageTwoDetailSix">              
            <div className="ContentProjectsViewImgageRight">
                <img loading="lazy" src={ImgFiveteen} alt="React Logo"  width="100%" />  
            </div>
        </div>  
        <div className="ContentProjectsViewImgageTwoDetailSix">              
            <div className="ContentProjectsViewImgageRight">
                <img loading="lazy" src={ImgSixteen} alt="React Logo"  width="100%" />  
            </div>
        </div>  
        <div className="ContentProjectsViewImgageTwoDetailSix">              
            <div className="ContentProjectsViewImgageRight">
                <img loading="lazy" src={ImgEightteen} alt="React Logo"  width="100%" />  
            </div>
        </div>  
        <div className="ContentProjectsViewImgageTwoDetailSix">              
            <div className="ContentProjectsViewImgageRight">
                <img loading="lazy" src={ImgNineteen} alt="React Logo"  width="100%" />  
            </div>
        </div>  
        <div className="ContentProjectsViewImgageTwoDetailSix">              
            <div className="ContentProjectsViewImgageRight">
                <img loading="lazy" src={ImgTwenty} alt="React Logo"  width="100%" />  
            </div>
        </div>  
        <div className="ContentProjectsViewImgageTwoDetailSix">              
            <div className="ContentProjectsViewImgageRight">
                <img loading="lazy" src={ImgTwentyOne} alt="React Logo"  width="100%" />  
            </div>
        </div>  



        </div>


        <PageSeven></PageSeven>
    </div>
    )
}

export default DetailProjectSix;