import React from "react";

function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79.213"
      height="27.905"
      data-name="Grupo 4"
      viewBox="0 0 79.213 27.905"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            d="M0 0H79.213V27.905H0z"
            data-name="Rectángulo 2"
          ></path>
        </clipPath>
      </defs>
      <g fill={props.color} clipPath="url(#clip-path)" data-name="Grupo 3">
        <path
          d="M6.03 0H.049L0 20.93c-.012 4.5 2.835 6.964 6.7 6.975a21.883 21.883 0 004.754-.826V23.3a15.12 15.12 0 01-3.332-.231c-1.268-.4-2.149-1.149-2.143-3.141L6 11.309l5.746.014.012-4.183-5.742-.014z"
          data-name="Trazado 22"
        ></path>
        <path
          d="M92.937 12.982A6.092 6.092 0 0097.71 6.94C97.721 2.692 93.954.049 87.9.033L75.237 0l-.071 27.558H88.26c6.37.017 10.388-2.882 10.4-7.6a6.72 6.72 0 00-5.722-6.976m-11.5-7.894l6.134.016c2.358-.032 3.771 1.072 3.767 2.961 0 1.926-1.424 3.063-3.782 3.057l-6.134-.015zm6.089 17.4l-6.133-.015.017-6.529 6.133.015c2.909-.031 4.716 1.193 4.711 3.2-.006 2.123-1.819 3.339-4.728 3.331"
          data-name="Trazado 23"
          transform="translate(-58.166 -.001)"
        ></path>
        <path
          d="M207.359 16.28l13.239.034.012-5.146-13.239-.034.015-5.972 14.731.039.014-5.2h-21.01v27.563h21.373l.012-5.155-15.165-.038z"
          data-name="Trazado 24"
          transform="translate(-155.634)"
        ></path>
        <path
          d="M324.559 94.424a3.275 3.275 0 103.267 3.292 3.221 3.221 0 00-3.267-3.292m-.014 5.914a2.646 2.646 0 112.627-2.624 2.6 2.6 0 01-2.627 2.624"
          data-name="Trazado 25"
          transform="translate(-248.613 -73.068)"
        ></path>
        <path
          d="M333.093 102.852c0-.6-.491-1-1.226-1h-1.392l-.008 3.006h.655v-.964h.491l.847.966h.786l-.93-1.032a1.012 1.012 0 00.776-.978m-1.233.453h-.735v-.864h.735c.341 0 .569.165.569.425s-.23.44-.571.439"
          data-name="Trazado 26"
          transform="translate(-255.726 -78.814)"
        ></path>
      </g>
    </svg>
  );
}

export default Logo;